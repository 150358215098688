import { Getters, GettersResult } from '@/store/cart/CartTypes'
import leftoversModule from '@/store/leftovers'
import { round } from 'lodash-es'

export const getters: Getters = {

  /**
   * Продукты содержащиеся в корзине
   * @param s
   */
  products(s) {
    return s.items.reduce((products, { productId, count }) => {
      if (!count) {
        return products
      }
      const leftover = leftoversModule
        .mapLeftovers?.find(leftover => leftover?.product.id === productId)
      if (!leftover) {
        return products
      }
      const { product, currentAmount, tag, price } = leftover
      if (typeof price !== 'number') {
        return products
      }
      if (!product) {
        return products
      }
      const { title, calories, carbohydrates, fats, proteins, weight, img } = product
      products.push({
        id: productId,
        quality: count,
        priceWithoutDiscounts: product.salePrice,
        title,
        calories,
        carbohydrates,
        fats,
        tag,
        currentAmount,
        proteins,
        weight,
        priceWithDiscount: price,
        img: img || undefined
      })
      return products
    }, <GettersResult['products']>[])
  },

  /**
   * Продукты содержащиеся в истории и удалённые из корзины
   * @param s
   */
  productsHistory(s) {
    return s.itemsHistory.reduce((productsHistory, productId) => {
      if (s.items.find((item) => item.productId === productId)) {
        return productsHistory
      }

      const leftover = leftoversModule
        .mapLeftovers?.find(leftover => leftover?.product.id === productId)
      if (!leftover) {
        return productsHistory
      }
      const { product, currentAmount, tag } = leftover
      if (!product) {
        return productsHistory
      }
      const { title, calories, carbohydrates, fats, proteins, weight } = product
      productsHistory.push({
        id: productId,
        priceWithoutDiscounts: product.salePrice,
        title,
        calories,
        carbohydrates,
        fats,
        tag,
        currentAmount,
        proteins,
        weight
      })
      return productsHistory
    }, <GettersResult['productsHistory']>[])
  },

  /**
   * Стоимость с учетом скидок, рассчитаая на фронте
   * @param s
   * @param g
   */
  frontPrice(s, g) {
    return round(s.items.reduce((price, { productId, count }) => {
      const priceWithDiscounts = g.products.find(({ id }) => id === productId)?.priceWithDiscount
      return price + ((priceWithDiscounts ?? 0) * (count ?? 0))
    }, 0), 2)
  },

  /**
   * Общая стоимость
   * @param s
   * @param g
   */
  commonPrice(s, g) {
    return round(s.items.reduce((commonPrice, { productId, count }) => {
      const price = g.products.find(({ id }) => id === productId)?.priceWithoutDiscounts
      return commonPrice + ((price ?? 0) * (count ?? 0))
    }, 0), 2)
  },

  /**
   * Общая стоимость с учетом скидок
   * @param s
   * @param g
   */
  totalPrice(s, g) {
    return round(s.items.reduce((totalPrice, { productId, count }) => {
      const price = g.products.find(({ id }) => id === productId)?.priceWithDiscount
      return totalPrice + ((price ?? 0) * (count ?? 0))
    }, 0), 2)
  }

}
