import { createModule } from 'vuexok'
import store from '@/store/index'
import { apolloClient } from '@/plugins/apollo/default'
import { logBreadcrumb } from '@/core/logger'
import Sentry from '@/plugins/sentry'
import {
  operatorAuth,
  OperatorAuthMutation,
  OperatorAuthMutationVariables
} from '@/graphql/default/authentication.graphql'
type State = {
  /**
   * Персональный код оператора
   */
  personalCode: string
  /**
   * ID оператора, определяет аутентификацию
   */
  operatorId: string
  /**
   * Ошибка
   */
  error: string
}
interface OperatorInterface {
  /**
   * Id оператора
   */
  readonly operatorId: State['operatorId']
  /**
   * Ошибка
   */
  readonly error: State['error']
  /**
   * Установить код оператора
   */
  setPersonalCode(code: string): Promise<void>
  /**
   * Сбрасывает сервисный режим
   */
  resetServiceMode(): void
}
const tag = 'operatorModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'gray' }, ...arg)

const createCustomerModule = () => {
  const stateInit = ():State => ({
    personalCode: '',
    operatorId: '',
    error: ''
  })
  const vuexModule = createModule(tag, {
    namespaced: true,
    state: stateInit,
    mutations: {
      setPersonalCode(state, code: State['personalCode']) {
        log('Записываем код оператора', code)
        state.personalCode = code
      },
      setOperatorId(state, id: State['operatorId']) {
        log('Записываем ID оператора', id)
        state.operatorId = id
      },
      resetOperator(state) {
        log('Сбрасываем аутентификацию оператора')
        const emptyState = stateInit()
        Object.assign(state, emptyState)
      },
      setError(state, error: State['error']) {
        state.error = error
      }
    },
    actions: {
      async authenticateOperator(context) {
        log('Запускаем аутентификацию оператора')
        vuexModule.mutations.setError('')
        const personalCode = context.state.personalCode
        const timeStampStartMutate = Date.now()
        await apolloClient
          .mutate<OperatorAuthMutation,
            OperatorAuthMutationVariables>({
              mutation: operatorAuth,
              variables: {
                personalCode
              }
            })
          .then(result => {
            const operatorId = result.data?.operatorAuth?.operator?.userId
            if (operatorId) {
              vuexModule.mutations.setOperatorId(operatorId)
            } else {
              vuexModule.mutations.setError('Ошибка аутентификации оператора')
            }
          })
          .finally(() => {
            const timeStampFinishMutate = Date.now()
            Sentry.captureMessage('Мутация operatorAuth', {
              tags: {
                start: timeStampStartMutate,
                finish: timeStampFinishMutate,
                lasting: timeStampFinishMutate - timeStampStartMutate
              }
            })
          })
      }
    }
  })
  vuexModule.register(store)

  const operatorInterface: OperatorInterface = {
    get operatorId() {
      return vuexModule.state.operatorId
    },
    get error() {
      return vuexModule.state.error
    },
    async setPersonalCode(code: string) {
      vuexModule.mutations.setPersonalCode(code)
      await vuexModule.actions.authenticateOperator()
    },
    resetServiceMode() {
      vuexModule.mutations.resetOperator()
    }
  }

  return operatorInterface
}
export const operator: OperatorInterface = createCustomerModule()
