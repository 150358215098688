import { ErrorTypes } from '@/graphql/terminal/graphql.schema'
import { CartType } from '@/graphql/default/graphql.schema'

export class ServerErrors extends Error {
  constructor(message:string, readonly type?: ErrorTypes) {
    super(message)
  }
}

export class CartError extends Error {
  constructor(message = 'Неизвестная ошибка корзины', readonly cart?: Partial<CartType>) {
    super(message)
  }
}

export class CartAPIError extends Error {
  constructor(message = 'Неизвестная ошибка API корзины', readonly description?: string, readonly response?: unknown) {
    super(message)
  }
}
