import { createModule } from 'vuexok'
import store from '.'
import { logBreadcrumb } from '@/core/logger'
import Sentry from '@/plugins/sentry'
import { Severity } from '@sentry/types'

/**
 * Статусы WebSocket соединения
 */
export enum WebSocketStatus {
  /**
   * Подключение
   */
  Connecting='Connecting',
  /**
   * Подключён
   */
  Connected='Connected',
  /**
   * Восстановление подключения
   */
  Reconnecting='Reconnecting',
  /**
   * Подключение восстановлено
   */
  Reconnected='Reconnected',
  /**
   * Подключение отсутствует
   */
  Disconnected='Disconnected',
  /**
   * Ошибка подключения
   */
  Error='Error'
}

/**
 * Статусы Http соединения
 */
export enum HttpStatus {
  /**
   * Подключение установлено
   */
  Ok='Ok',
  /**
   * Ошибка при запросе
   */
  Error='Error'
}

type WSConnectionStatuses = Record<string, WebSocketStatus>
type HttpConnectionStatuses = Record<string, HttpStatus>

type State = {
  ws: WSConnectionStatuses,
  http:HttpConnectionStatuses
}

type Getters = {
  allServicesAreAvailable: boolean,
}

const tag = 'connectionModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: '#5A8F66' }, ...arg)

const stateInit = (): State => ({
  ws: {
  },
  http: {
  }
})

export const connectionModule = createModule(tag, {
  namespaced: true,
  state: stateInit,
  mutations: {
    /**
     * Записать статус websocket соединения
     * @param state
     * @param s
     * @param name
     * @param status
     */
    setStatusWebSocket(s, { name, status }:{
      name: keyof State['ws'],
      status: WebSocketStatus
    }) {
      log('Записываем статус WS', name, status)
      s.ws = { ...s.ws, [name]: status }
      if ([WebSocketStatus.Disconnected, WebSocketStatus.Reconnecting, WebSocketStatus.Error].includes(status)) {
        Sentry.captureMessage(`Проблемы с WS соединением, статус ${status}`, { level: Severity.Warning })
      }
    },
    setStatusHttp(s, { name, status }:{
      name: keyof State['http'],
      status: HttpStatus
    }) {
      log('Записываем статус HTTP', name, status)
      s.http = { ...s.http, [name]: status }
      if (HttpStatus.Error === status) {
        Sentry.captureMessage(`Проблемы с HTTP соединением, статус ${status}`, { level: Severity.Warning })
      }
    }
  },
  getters: {
    allServicesAreAvailable(s): Getters['allServicesAreAvailable'] {
      const positiveWebSocket = [WebSocketStatus.Connected,
        WebSocketStatus.Connecting, WebSocketStatus.Reconnected, WebSocketStatus.Reconnected]

      return !Object.values(s.ws).find(status => !positiveWebSocket.includes(status))
    }
  }
})

connectionModule.register(store)

export default connectionModule
