import { createModule } from 'vuexok'
import store from '@/store/index'
import { register } from 'register-service-worker'
import { logBreadcrumb } from '@/core/logger'
import { settingsModule } from '@/store/settings'
import { userTrackerModule } from '@/store/userTracker'
import Sentry from '@/plugins/sentry'

type State = {
  updateReady: boolean,
  swRegistered: boolean
}

const tag = 'updaterModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'Fuchsia' }, ...arg)

const state = ():State => ({
  updateReady: false,
  swRegistered: false
})

export const updaterModule = createModule(tag, {
  namespaced: true,
  state,
  mutations: {
    setUpdateReady(state, updateReady: State['updateReady']) {
      log('Запоминаем состояние готовности обновления', updateReady)
      state.updateReady = updateReady
    },
    setSWRegistered(state, swRegistered: State['swRegistered']) {
      log('Запоминаем регистрацию сервис воркера', swRegistered)
      state.swRegistered = swRegistered
    }
  },
  actions: {
    /**
     * Подготавливаем всё для работы обновления
     */
    async start() {
      log('Запускаем регистрацию сервис воркера и проверку обновления')
      if (process.env.NODE_ENV === 'production') {
        register(`${settingsModule.state.requiredSettings.BASE_URL || ''}service-worker.js`, {
          ready() {
            log('👌 Приложение обслуживается из кеша')
          },
          registered() {
            log('🥇 SW зарегистрирован.')
            updaterModule.mutations.setSWRegistered(true)
          },
          cached() {
            log('🏁 Контент кэширован для использования в автономном режиме.')
          },
          updatefound() {
            log('🔃 Загружается новый контент.')
          },
          updated() {
            log('🆕 Доступно обновление')
            Sentry.captureMessage('🆕 Доступно обновление')
            updaterModule.mutations.setUpdateReady(true)
            updaterModule.actions.replaceApp()
          },
          offline() {
            log('⛔ Нет подключения к Интернету. Приложение работает в автономном режиме.')
          },
          error(error) {
            log('💥 Ошибка при регистрации сервис-воркера:', error)
          }
        })
      }

      // периодически проверяем обновление
      setInterval(() => {
        updaterModule.actions.startUpdate()
      }, settingsModule.state.publicSettings.howOftenToCheckForAppUpdates)
    },
    /**
     * Проверяем обновление на сервере
     */
    async updateCheck() {
      log('Проверяем обновление на сервере')
      const swRegistration = await navigator.serviceWorker?.getRegistration?.()
      if (swRegistration) {
        await swRegistration.update()
      }
    },
    /**
     * Обновляем страницу
     */
    async replaceApp() {
      log('Подменяем приложение на новое')
      const waitingSW = (await window.navigator.serviceWorker.ready).waiting
      if (waitingSW && !userTrackerModule.state.userHere) {
        Sentry.captureMessage('Подменяем приложение на новое')
        window.navigator.serviceWorker.addEventListener('controllerchange', () => {
          // ждём controllerchange перед перезагрузкой, т.к. sw может не успеть замениться
          log('Перезагружаем приложение после обновления')
          Sentry.captureMessage('Перезагружаем приложение после обновления')
          window.location.reload()
        })
        log('Заменяем SW на новый')
        waitingSW.postMessage({ type: 'SKIP_WAITING' })
        updaterModule.mutations.setUpdateReady(false)
      }
    },
    /**
     * Предпринимаем попытку обновления приложения
     */
    async startUpdate({ state }) {
      log('Запускаем сценарий обновления')
      if (!state.swRegistered && !userTrackerModule.state.userHere) {
        log('sw не зарегистрирован, перезагружаем страницу')
        window.location.reload()
      }
      if (updaterModule.state.updateReady) {
        await updaterModule.actions.replaceApp()
      } else {
        await updaterModule.actions.updateCheck()
      }
    }
  }
})

updaterModule.register(store)

updaterModule.actions.start()
