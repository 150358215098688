import '@/assets/stylesheets/main.scss'
import Vue from 'vue'
import './router/registerHooks'
import './plugins/sentry'
import './plugins/toast'
import './plugins/vue-lazyload'
import './plugins/modal'
import App from './App.vue'
import router from './router'
import store from './store'
import logsModules from '@/store/logs'
import { settingsModule } from '@/store/settings'

Vue.config.productionTip = false

export const main = new Vue({
  name: 'AppMain',
  created() {
    console.log('--INFO', INFO)
  },
  router,
  store,
  render: h => h(App),
  errorCaptured(err: Error): void {
    if (settingsModule.state.publicSettings.showAllErrors) {
      this.$bvToast.toast(err.message, {
        title: `Error: ${err.name}`,
        variant: 'danger'
      })
    }
    logsModules.mutations.addError(err)
  }

}).$mount('#app')
