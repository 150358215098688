import VueLazyload from 'vue-lazyload'
import Vue from 'vue'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from '@/assets/images/svg/button-idontknowwhatiwant.svg'

Vue.use(VueLazyload, {
  error: img,
  loading: img
})
