import { createModule } from 'vuexok'
import store from '.'
import { SubscribeOptions } from '@/core/subscribeHelpers'
import { cloneDeep, mergeWith } from 'lodash-es'
import { Maybe } from 'graphql/jsutils/Maybe'
import category from '@/store/category'
import { waitTimer } from '@/core/pendings'
import { logBreadcrumb } from '@/core/logger'
import { settingsModule } from '@/store/settings'
import { FetchMoreOptions, FetchMoreQueryOptions, ObservableQuery } from '@apollo/client'
import { apolloClient } from '@/plugins/apollo/default'
import { mergeDeep } from '@apollo/client/utilities'
import { Scalars, TerminalLogTypes } from '@/graphql/default/graphql.schema'
import {
  leftoverAdded,
  LeftoverAddedSubscription,
  LeftoverAddedSubscriptionVariables,
  leftoverDeleted,
  LeftoverDeletedSubscription,
  LeftoverDeletedSubscriptionVariables,
  LeftoverFragment,
  leftovers,
  LeftoversQuery,
  LeftoversQueryVariables,
  leftoverUpdated,
  LeftoverUpdatedSubscription,
  LeftoverUpdatedSubscriptionVariables,
  scancode,
  ScancodeQuery,
  ScancodeQueryVariables
} from '@/graphql/default/leftovers.graphql'
import {
  terminalLogCreate,
  TerminalLogCreateMutation,
  TerminalLogCreateMutationVariables
} from '@/graphql/default/terminalLogs.graphql'
import terminalModule from '@/store/terminal'
import priceModificationModule from '@/store/priceModification'
import Sentry from '@/plugins/sentry'
import { Severity } from '@sentry/types'

const terminalService = () => import('@/store/terminalService')
  .then(({ terminalService }) => (terminalService))

export type BatchesObject = { id: Scalars['String'], count: Scalars['Int']}

type State = {
  /**
   * Остатки на терминале
   */
  leftovers: LeftoversQuery['leftovers'],
  variables: LeftoversQueryVariables,
  loading: boolean
}

type CustomFieldLeftovers = {
  batchesObject?: BatchesObject,
  tag?: 'new'| 'discount',
  price?: Maybe<number>,
  product: {
    img?: Maybe<string>
  }
}

type Getters = {
  readonly mapLeftovers: Maybe<Array<Maybe<(
    { __typename: 'LeftoverType' }
    & LeftoverFragment
    & CustomFieldLeftovers
    )>>>,
  readonly newLeftovers: Getters['mapLeftovers']
  readonly threeNewLeftovers: Getters['newLeftovers']
  readonly availableLeftovers: Getters['mapLeftovers']
}

const tag = 'leftoversModule'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'blue' }, ...arg)

function createLeftoversModule() {
  log('createLeftoversModule')

  const state: State = {
    leftovers: null,
    variables: {
      orderBy: ['product__updatedAt'],
      after: null,
      first: 50
    },
    loading: false
  }

  let observableGetLeftovers: ObservableQuery<
    LeftoversQuery,
    LeftoversQueryVariables
    > | undefined

  /**
   * Загрузка остатков
   */
  function loadLeftovers():Promise<LeftoversQuery['leftovers']> {
    const queryVariables = {
      orderBy: ['product__updatedAt'],
      after: null,
      first: 50
    }

    const observable = apolloClient
      .watchQuery<LeftoversQuery, LeftoversQueryVariables>({
        query: leftovers,
        variables: queryVariables,
        errorPolicy: 'all'
      })

    let stateLeftovers: LeftoversQuery['leftovers'] = null

    return new Promise((resolve, reject) => {
      const subscribeQuery = observable
        .subscribe(({
          data: {
            leftovers
          }
        }) => {
          const prevLength = stateLeftovers?.edges.length
          stateLeftovers = cloneDeep(leftovers)
          const postLength = stateLeftovers?.edges.length
          const first = queryVariables.first
          if (typeof postLength !== 'number') {
            subscribeQuery.unsubscribe()
            resolve(stateLeftovers)
          } else if (
            !prevLength ||
            postLength - prevLength >= first ||
            postLength - prevLength < 0
          ) {
            const fetchMoreOptions: FetchMoreOptions<LeftoversQuery, LeftoversQueryVariables>
              & FetchMoreQueryOptions<LeftoversQueryVariables, 'after'> = {
                updateQuery(previous, {
                  fetchMoreResult
                }) {
                  const previousQueryResult = cloneDeep(previous)
                  return mergeWith(previousQueryResult, fetchMoreResult, (prev, fetch) => {
                    if (Array.isArray(prev)) {
                      if (prev[0]?.node?.id) {
                      // фильтруем общий массив от повторяющихся id
                        return prev.concat(fetch).reduce((
                          accumulator: { node?: { id?: Scalars['ID'] } }[],
                          currentValue: { node?: { id?: Scalars['ID'] } }
                        ) => {
                          if (!accumulator.find((value) => (value?.node?.id === currentValue?.node?.id))) {
                            accumulator.push(currentValue)
                          }
                          return accumulator
                        }, [])
                      } else {
                        return prev.concat(fetch)
                      }
                    }
                  })
                },
                variables: {
                  after: stateLeftovers?.pageInfo.endCursor || null
                }
              }
            observableGetLeftovers?.fetchMore(fetchMoreOptions)
          } else {
            if (!stateLeftovers?.edges.length) {
              subscribeQuery.unsubscribe()
              return reject(new Error('Нет остатков'))
            }
            if (stateLeftovers.edges.length !== stateLeftovers.totalCount) {
              subscribeQuery.unsubscribe()
              return reject(new Error('Загруженные остатки не соответствуют их общему количеству'))
            }
            subscribeQuery.unsubscribe()
            resolve(stateLeftovers)
          }
        },
        reject
        )
    })
  }

  /**
   * Циклическая загрузка остатков игнорируя ошибки
   */
  const repetitiveLoadLeftovers = async() => {
    try {
      await loadLeftovers()
    } catch (e) {
      let message = ''
      if (typeof e === 'string') {
        message = 'Ошибка в загрузке остатков'
      } else if (e instanceof Error) {
        message = e.message
      }
      log(message)
      Sentry.captureMessage(message, Severity.Error)
      await repetitiveLoadLeftovers()
    }
  }

  const vuexModule = createModule(tag, {
    namespaced: true,
    state,
    mutations: {
      setLeftovers(state, leftovers: LeftoversQuery['leftovers']) {
        state.leftovers = cloneDeep(leftovers)
      },
      setLoading(state, isLoading: boolean) {
        state.loading = isLoading
      }
    },
    actions: {
      /**
       * Удаление остатков
       */
      async clearLeftovers() {
        log('Очищаем Leftovers из кэша Apollo')
        const queryVariables = state.variables
        apolloClient.writeQuery<LeftoversQuery, LeftoversQueryVariables>({
          query: leftovers,
          variables: queryVariables,
          data: {
            __typename: 'Query',
            leftovers: {
              __typename: 'LeftoverTypeConnection',
              edges: [],
              pageInfo: {
                __typename: 'PageInfo',
                endCursor: null
              }
            }
          }
        })
      },
      /**
       * Старт подписки на изменение остатков
       */
      async startWatchingSubscriptionsAndQueries() {
        log('Запускаем процесс отслеживания состояния остатков')
        const timeoutBetweenRetries = 60e3
        const queryVariables = {
          orderBy: ['product__updatedAt'],
          after: null,
          first: 50
        }

        try {
          if (observableGetLeftovers) {
            log('Обновляем остатки')
            await observableGetLeftovers.refetch(queryVariables)
          } else {
            log('Подписываемся на остатки')
            const mac = await (await terminalService()).getMAC()

            const subscribeVariables = {
              mac
            }

            observableGetLeftovers = apolloClient
              .watchQuery<LeftoversQuery, LeftoversQueryVariables>({
                query: leftovers,
                variables: queryVariables,
                errorPolicy: 'all'
              })
            observableGetLeftovers
              .subscribe(({
                data: {
                  leftovers
                }
              }) => {
                vuexModule.mutations.setLeftovers(leftovers)
              })

            observableGetLeftovers.subscribeToMore(new SubscribeOptions<LeftoversQuery,
              LeftoverAddedSubscription,
              LeftoverAddedSubscriptionVariables,
              LeftoversQueryVariables>({
                type: 'added',
                subscribeDocument: leftoverAdded,
                queryDocument: leftovers,
                variables: subscribeVariables,
                queryVariables
              }))
            observableGetLeftovers.subscribeToMore(new SubscribeOptions<LeftoversQuery,
              LeftoverUpdatedSubscription,
              LeftoverUpdatedSubscriptionVariables,
              LeftoversQueryVariables>({
                type: 'updated',
                subscribeDocument: leftoverUpdated,
                queryDocument: leftovers,
                variables: subscribeVariables,
                queryVariables
              }))
            observableGetLeftovers.subscribeToMore(new SubscribeOptions<LeftoversQuery,
              LeftoverDeletedSubscription,
              LeftoverDeletedSubscriptionVariables,
              LeftoversQueryVariables>({
                type: 'deleted',
                subscribeDocument: leftoverDeleted,
                queryDocument: leftovers,
                variables: subscribeVariables,
                queryVariables
              }))
          }
        } catch (error) {
          log('Возникла ошибка при запуске отслеживания остатков', { error })
          waitTimer(timeoutBetweenRetries)
            .catch(vuexModule.actions.startWatchingSubscriptionsAndQueries)
          throw error
        }
      },
      async findLeftoverByBarcode({ state }, barcode: string) {
        log('Поиск продукта по баркоду', barcode)
        const productId = (await apolloClient.query<ScancodeQuery, ScancodeQueryVariables>({
          query: scancode,
          variables: {
            code: barcode
          }
        })).data.scancode?.edges[0]?.node?.product.id
        if (!productId) {
          apolloClient.mutate<TerminalLogCreateMutation, TerminalLogCreateMutationVariables>({
            mutation: terminalLogCreate,
            variables: {
              logType: TerminalLogTypes.BarcodeFailed,
              message: JSON.stringify({
                message: `Не нашли продукт по бар-коду "${barcode}"`
              })
            }
          })
          // throw new Error(`Не нашли продукт по бар-коду "${barcode}"`)
          return undefined
        } else {
          const isLeftoversThere = state.leftovers?.edges?.length
          const foundLeftover = state.leftovers?.edges
            .find((edge) => edge?.node?.product.id === productId)
          const terminalId = terminalModule.getters.terminal?.id
          const decodedId = atob ? atob(productId) : ''
          const isNeedToLog = !!(isLeftoversThere && !foundLeftover && terminalId)
          if (isNeedToLog) {
            apolloClient.mutate<TerminalLogCreateMutation, TerminalLogCreateMutationVariables>({
              mutation: terminalLogCreate,
              variables: {
                logType: TerminalLogTypes.AssortmentNotFound,
                message: JSON.stringify({
                  // Debug: поиск проблемных штрихкодов
                  message: `Товара "${decodedId || productId}" нет в ассортименте терминала "${terminalId}"` +
                    `(barcode: ${barcode}, isLeftoversThere: ${isLeftoversThere})`
                })
              }
            })
            // throw new Error(`Не нашли остатки по id "${decodedId || productId}"`)
            return undefined
          }
          return foundLeftover
        }
      }
    },
    getters: {
      mapLeftovers(state): Getters['mapLeftovers'] {
        return state.leftovers?.edges.map(edge => {
          if (edge?.node?.batches) {
            const { node } = edge

            const newProductCategoryId = category.getters.newProductCategory?.node?.id
            const isNew = !!node.product.subcategory.edges.find(edge => edge?.node?.id === newProductCategoryId)

            const batches = JSON.parse(node.batches) as { [k: string]: number }
            const entries = Object.entries(batches)
            if (entries.length) {
              const [[id, count]] = Object.entries(batches)
              const batchesObject: BatchesObject = { id, count }
              const absoluteImageAddress = node.product.img ? settingsModule.getters.mediaPath + node.product.img : null
              const prices = priceModificationModule.priceModification
              let isSale = false
              const priceWithSale = prices?.edges.reduce((acc, price) => {
                if (price?.node) {
                  let difference = 0
                  const priceCategoryId = price.node.catalog.edges.length && price.node.catalog.edges.map(item => item?.node && item?.node.id)
                  const isSaleCatalog = priceCategoryId && priceCategoryId.find(item => item === node.product?.catalog?.id)
                  const saleProduct = price?.node?.product && price.node.product.id === node.product.id
                  const isAllSale = !price.node.catalog.edges.length && !price?.node?.product

                  if (isSaleCatalog || saleProduct || isAllSale) {
                    if (node.product.salePrice && price.node.percent) {
                      isSale = price.node.percent < 0
                      difference = node.product.salePrice * (Math.abs(price.node.percent) / 100)
                    } else if (price.node.fixed) {
                      isSale = price.node.fixed < 0
                      difference = Math.abs(price.node.fixed)
                    }

                    if (node.price && node.product.salePrice) {
                      const calcPrice = isSale ? node.product.salePrice - difference : node.product.salePrice + difference
                      if ((!isSale && node.product.salePrice === acc) || calcPrice < node.price) {
                        acc = calcPrice
                      }
                    }
                  }
                }
                return acc
              }, node.product.salePrice)

              return mergeDeep<[typeof node, CustomFieldLeftovers] >(node, {
                batchesObject,
                price: priceWithSale && +(priceWithSale).toFixed(2),
                product: {
                  img: absoluteImageAddress
                },
                tag: priceWithSale && priceWithSale < node.product.salePrice ? 'discount' : isNew ? 'new' : undefined
              })
            }
          }
          return edge?.node
        })
      },

      newLeftovers(state, getters: Getters): Getters['newLeftovers'] {
        const subcategory = category.getters.newProductCategory
        const subcategoryId = subcategory?.node?.id
        if (subcategoryId) {
          return getters.availableLeftovers?.filter((node: Maybe<(
            { __typename: 'LeftoverType' } & LeftoverFragment & { batchesObject?:BatchesObject }
            )>) => {
            return node?.product.subcategory.edges.find(edge => edge?.node?.id === subcategoryId)
          })
        }
        return undefined
      },

      threeNewLeftovers(state, getters: Getters): Getters['threeNewLeftovers'] {
        const leftovers = getters.newLeftovers
        if (!leftovers?.length) return []
        return leftovers.splice(0, 3)
      },

      availableLeftovers(state, getters: Getters): Getters['availableLeftovers'] {
        return getters.mapLeftovers?.filter(leftover => leftover && leftover.currentAmount > 0)
      }
    }
  })
  vuexModule.register(store)

  vuexModule.actions.startWatchingSubscriptionsAndQueries()

  return {
    get newLeftovers() {
      return vuexModule.getters.newLeftovers
    },
    get threeNewLeftovers() {
      return vuexModule.getters.threeNewLeftovers
    },
    get mapLeftovers() {
      return vuexModule.getters.mapLeftovers
    },
    get loading() {
      return vuexModule.state.loading
    },
    /**
     * Загрузка остатков
     */
    async load() {
      try {
        vuexModule.mutations.setLoading(true)
        await repetitiveLoadLeftovers()
      } finally {
        vuexModule.mutations.setLoading(false)
      }
    },
    findLeftoverByBarcode: vuexModule.actions.findLeftoverByBarcode,
    loadLeftovers
  }
}

export const leftoversModule = createLeftoversModule()

Object.assign(window, { leftoversModule })

export default leftoversModule
