import connection, { WebSocketStatus } from '@/store/connection'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { LinkConfigurator } from '@/plugins/apollo/LinkConfigurator'
import { settingsModule } from '@/store/settings'

const name = 'ws localhost'

const _normalizeUrl = (url: string): string => url.replace(/\/$/, '')

const linkConfigurator = new LinkConfigurator({
  async http() {
    const settingsUrl = (await settingsModule.actions.publicSettings())?.GRAPHQL_HTTP_TERMINAL
    const fallbackUrl = 'http://localhost:4000/graphql'
    return settingsUrl ? _normalizeUrl(settingsUrl) : fallbackUrl
  },
  async ws() {
    const settingsUrl = (await settingsModule.actions.publicSettings())?.GRAPHQL_WS_TERMINAL
    const fallbackUrl = 'ws://localhost:4000/graphql'
    return settingsUrl ? _normalizeUrl(settingsUrl) : fallbackUrl
  },
  wsHandlers: {
    onConnecting: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Connecting
      })
    },
    onConnected: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Connected
      })
    },
    onReconnecting: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Reconnecting
      })
    },
    onReconnected: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Reconnected
      })
    },
    onDisconnected: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Disconnected
      })
    },
    onError: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Error
      })
    }
  }
})

export const apolloClient = new ApolloClient({
  link: linkConfigurator.link,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production'
})
