import { apolloClient } from '@/plugins/apollo/default'
import {
  CartFragment,
  cartPrice,
  cartPriceAdded,
  CartPriceAddedSubscription,
  CartPriceAddedSubscriptionVariables,
  CartPriceMutation,
  CartPriceMutationVariables,
  carts,
  CartsQuery,
  CartsQueryVariables,
  cartUpdated,
  CartUpdatedSubscription,
  CartUpdatedSubscriptionVariables,
  createCart as createCartGraphql,
  CreateCartMutation,
  CreateCartMutationVariables,
  deactualizeCart as deactualizeCartGraphql,
  DeactualizeCartMutation,
  DeactualizeCartMutationVariables,
  setWaitingPaymentCartStatus,
  SetWaitingPaymentCartStatusMutation,
  SetWaitingPaymentCartStatusMutationVariables
} from '@/graphql/default/carts.graphql'
import { logBreadcrumb as logger } from '@/core/logger'
import { CartAPIError } from '@/store/cart/serverErrors'
import { cloneDeep, merge } from 'lodash-es'
import { CartStatus, ItemsInputType, Scalars } from '@/graphql/default/graphql.schema'
import { v4 } from 'uuid'
import { cart } from '@/store/cart/cartModule'
import Sentry from '@/plugins/sentry'
import { FetchResult } from '@apollo/client'
import { Observable } from '@apollo/client/utilities'

const terminalServiceModule = () =>
  import('@/store/terminalService').then(
    ({ terminalService }) => terminalService
  )

const TAG = 'cartAPI'
const log = (...arg: Parameters<Console['log']>) =>
  logger({ tag: TAG, color: '#DA9BAD' }, ...arg)

/**
 * Запросить корзину с сервера
 */
export const getCart = async() => {
  log('Запрашиваем корзину с сервера')
  return (
    await apolloClient.query<CartsQuery, CartsQueryVariables>({
      query: carts,
      variables: { orderBy: ['-createdAt'] },
      fetchPolicy: 'network-only'
    })
  ).data.carts?.edges[0]?.node
}

/**
 * Создать новую корзину на сервере
 */
export const createNewCart = async() => {
  log('Создаём новую корзину на сервере')
  const result = await apolloClient.mutate<
    CreateCartMutation,
    CreateCartMutationVariables
  >({
    mutation: createCartGraphql,
    update: (proxyData, result) => {
      const newCart = result.data?.createCart?.cart
      if (!newCart) {
        throw new CartAPIError('Не получили новую корзину', 'Мутация создания корзины не прислала корзину в ответ', result)
      }
      const variables = { orderBy: ['-createdAt'] }

      const oldData = proxyData.readQuery<CartsQuery, CartsQueryVariables>({
        query: carts,
        variables
      })
      proxyData.writeQuery<CartsQuery, CartsQueryVariables>({
        query: carts,
        variables,
        data: merge({}, oldData, {
          carts: {
            edges: [{ __typename: 'CartTypeEdge', node: newCart }]
          }
        })
      })
      return proxyData
    }
  })
  return result.data?.createCart?.cart
}

/**
 * Деактуализируем корзину и удаляем её из кэша
 * @param cartId
 */
export const deactualizeCart = async(cartId: Scalars['ID']): Promise<void> => {
  log('Деактуализируем корзину', { cartId })
  if (!cartId) {
    log('Нет корзины для деактуализации')
    return
  }
  await apolloClient.mutate<
    DeactualizeCartMutation,
    DeactualizeCartMutationVariables
  >({
    mutation: deactualizeCartGraphql,
    variables: {
      cartId
    }
  })
  apolloClient.writeQuery<CartsQuery, CartsQueryVariables>({
    query: carts,
    variables: { orderBy: ['-createdAt'] },
    data: {
      __typename: 'Query',
      carts: {
        __typename: 'CartTypeConnection',
        edges: []
      }
    }
  })
}

/**
 * Получить новую корзину
 */
export const getNewServerCart = async(): Promise<CartFragment> => {
  log('Запрашиваем серверную корзину')
  const receivedCart = await getCart()
  if (receivedCart) {
    if (receivedCart.status === CartStatus.New) {
      return receivedCart
    } else {
      await deactualizeCart(receivedCart?.id)
    }
  }
  const createdCart = await createNewCart()
  if (createdCart?.status === CartStatus.New) {
    return createdCart
  }
  throw new CartAPIError('Не удалось получить новую серверную корзину', 'Не смогли получить созданную и создать новую корзину', createdCart)
}

let observableCartUpdated:
  | Observable<FetchResult<CartUpdatedSubscription>>
  | undefined
/**
 * Включаем подписку на изменение серверной корзины
 */
const useSubscribeCartUpdated = async() => {
  if (observableCartUpdated) {
    return observableCartUpdated
  }
  const macAddress = await ((
    await terminalServiceModule()
  )).getMAC()
  // подписываемся на cartPriceAdded
  observableCartUpdated = apolloClient.subscribe<
    CartUpdatedSubscription,
    CartUpdatedSubscriptionVariables
  >({
    query: cartUpdated,
    variables: {
      mac: macAddress
    },
    errorPolicy: 'all'
  })
  return observableCartUpdated
}

/**
 * Перевод корзины в статус ожидания
 * @param id - id корзины
 * @param products - список id продуктов и их кол-ва
 * @param codes - список отсканированных кодов
 * @param waitResponse - время ожидания ответа в подписке
 */
export const setWaitPayment = async(
  id: Scalars['ID'],
  products: ItemsInputType[],
  codes: string[],
  waitResponse: number | undefined = 30e3
): Promise<NonNullable<CartUpdatedSubscription['cartUpdated']>['cart']> => {
  const cartId = id
  const items = cloneDeep(products)
  const promoCodes = cloneDeep(codes)

  log('Переводим корзину в статус ожидания')
  const timeout = setTimeout(() => {
    Sentry.captureMessage(
      'Ожидание корзины в статусе "waitPayment" более 10 секунд',
      { extra: { cartId, items, promoCodes } }
    )
  }, 10e3)

  const observableCartUpdated = await useSubscribeCartUpdated()
  log('Получаем подписку на изменение серверной корзины', observableCartUpdated)

  return new Promise((resolve, reject) => {
    const allowableResponseTimeout = setTimeout(() => {
      const message = `Не перевели корзину в статус ожидания за допустимый тайм-аут (${waitResponse} мс)`
      Sentry.captureMessage(
        message,
        { extra: { cartId, items, promoCodes } }
      )
      reject(
        new CartAPIError(message, 'Время ожидания перевода корзины в статус ожидания вышло.', { items, cartId, promoCodes })
      )
    }, waitResponse)

    observableCartUpdated.subscribe({
      next({ data, errors }: FetchResult<CartUpdatedSubscription>) {
        log('Подписываемся на изменение серверной корзины', { data, errors })

        if (errors?.length) {
          reject(errors)
        } else {
          const cart = data?.cartUpdated?.cart
          if (cart) {
            const id = cart.id
            const status = cart.status
            if (cartId === id && status === CartStatus.WaitPayment) {
              clearTimeout(timeout)
              clearTimeout(allowableResponseTimeout)
              resolve(cart)
            }
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error(e: any) {
        clearTimeout(timeout)
        clearTimeout(allowableResponseTimeout)
        reject(e)
      }
    })

    const timeStampStartMutate = Date.now()
    apolloClient
      .mutate<
        SetWaitingPaymentCartStatusMutation,
        SetWaitingPaymentCartStatusMutationVariables
      >({
        mutation: setWaitingPaymentCartStatus,
        variables: {
          cartId,
          items,
          promoCodes
        }
      })
      .catch(reject)
      .finally(() => {
        const timeStampFinishMutate = Date.now()
        cart.setShowObedRuCodeText(true)
        Sentry.captureMessage('Мутация setWaitingPaymentCartStatus', {
          tags: {
            start: timeStampStartMutate,
            finish: timeStampFinishMutate,
            lasting: timeStampFinishMutate - timeStampStartMutate
          }
        })
      })
  })
}

/**
 * Получить чек корзины
 * @param cardID
 * @param wait - время ожидания ответа от сервера
 */
export const getReceipt = async(
  cardID: Scalars['ID'],
  wait = 30e3
): Promise<NonNullable<CartFragment['receiptUrl']>> => {
  log('Получаем чек')
  const observableCartUpdated = await useSubscribeCartUpdated()

  return new Promise((resolve, reject) => {
    const allowableResponseTimeout = setTimeout(() => {
      reject(new CartAPIError(`Не получили чек за ${wait} мс`, undefined, { cardID }))
    }, wait)

    observableCartUpdated.subscribe({
      next({ data, errors }: FetchResult<CartUpdatedSubscription>) {
        if (errors?.length) {
          clearTimeout(allowableResponseTimeout)
          reject(errors[0])
        }
        const cart = data?.cartUpdated?.cart
        if (
          cart &&
          cart.id === cardID &&
          cart.status === CartStatus.ReceiptReceived &&
          cart.receiptUrl
        ) {
          log('Получили чек', cart.receiptUrl)
          clearTimeout(allowableResponseTimeout)
          resolve(cart.receiptUrl)
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error(e: any) {
        clearTimeout(allowableResponseTimeout)
        reject(e)
      }
    })
  })
}
