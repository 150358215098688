import { createModule } from 'vuexok'
import store from '@/store/index'
import { ErrorResponse } from '@apollo/client/link/error'

const tag = 'logsModules'

type ItemError = Error | ErrorResponse

type State = {
  /**
   * Список ошибок
   */
  errors: ItemError[]
}

const state = ():State => ({
  errors: []
})

export const logsModules = createModule(tag, {
  namespaced: true,
  state,
  mutations: {
    addError(state, e:ItemError) {
      state.errors.push(e)
      if (state.errors.length > 100) {
        state.errors.shift()
      }
    }
  }
})

logsModules.register(store)

export default logsModules
