import { MessageTypes, SubscriptionClient as OriginalSubscriptionClient } from 'subscriptions-transport-ws/dist/index'

/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/ban-ts-comment */

/**
 * Исправление клиента "subscriptions-transport-ws@0.11.0"
 * Исправление заключается в переносе старта подписок на этап после получения "connection_ack"
 */
// @ts-ignore
export class SubscriptionClient extends OriginalSubscriptionClient {
  private connect() {
    // @ts-ignore
    // eslint-disable-next-line new-cap
    this.client = new this.wsImpl(this.url, this.wsProtocols, ...this.wsOptionArguments)

    // @ts-ignore
    this.checkMaxConnectTimeout()

    this.client.onopen = async() => {
      // @ts-ignore
      if (this.status === this.wsImpl.OPEN) {
        // @ts-ignore
        this.clearMaxConnectTimeout()
        // @ts-ignore
        this.closedByUser = false
        // @ts-ignore
        this.eventEmitter.emit(this.reconnecting ? 'reconnecting' : 'connecting')

        try {
          // @ts-ignore
          const connectionParams: ConnectionParams = await this.connectionParams()

          // Send CONNECTION_INIT message, no need to wait for connection to success (reduce roundtrips)
          // @ts-ignore
          this.sendMessage(undefined, MessageTypes.GQL_CONNECTION_INIT, connectionParams)
          // @ts-ignore
          // this.flushUnsentMessagesQueue() // FIX: оригинальный вызов функции отправки очереди
        } catch (error) {
          // @ts-ignore
          this.sendMessage(undefined, MessageTypes.GQL_CONNECTION_ERROR, error)
          // @ts-ignore
          // this.flushUnsentMessagesQueue() // FIX: оригинальный вызов функции отправки очереди
        }
      }
    }

    this.client.onclose = () => {
      // @ts-ignore
      if (!this.closedByUser) {
        this.close(false, false)
      }
    }

    this.client.onerror = (err: Error) => {
      // Capture and ignore errors to prevent unhandled exceptions, wait for
      // onclose to fire before attempting a reconnect.
      // @ts-ignore
      this.eventEmitter.emit('error', err)
    }

    this.client.onmessage = ({ data }: {data: any}) => {
      // @ts-ignore
      this.processReceivedData(data)
    }
  }

  private processReceivedData(receivedData: any) {
    let parsedMessage: any
    let opId: string

    try {
      parsedMessage = JSON.parse(receivedData)
      opId = parsedMessage.id
    } catch (e) {
      throw new Error(`Message must be JSON-parseable. Got: ${receivedData}`)
    }

    if (
      [MessageTypes.GQL_DATA,
        MessageTypes.GQL_COMPLETE,
        MessageTypes.GQL_ERROR
      ].indexOf(parsedMessage.type) !== -1 && !this.operations[opId]
    ) {
      // @ts-ignore
      this.unsubscribe(opId)

      return
    }

    switch (parsedMessage.type) {
      case MessageTypes.GQL_CONNECTION_ERROR:
        // @ts-ignore
        if (this.connectionCallback) {
          // @ts-ignore
          this.connectionCallback(parsedMessage.payload)
        }
        break

      case MessageTypes.GQL_CONNECTION_ACK:
        // FIX: исправление отправки очереди после получения ответа готовности сервера
        // @ts-ignore
        this.flushUnsentMessagesQueue()
        // @ts-ignore
        this.eventEmitter.emit(this.reconnecting ? 'reconnected' : 'connected', parsedMessage.payload)
        // @ts-ignore
        this.reconnecting = false
        // @ts-ignore
        this.backoff.reset()
        // @ts-ignore
        this.maxConnectTimeGenerator.reset()

        // @ts-ignore
        if (this.connectionCallback) {
          // @ts-ignore
          this.connectionCallback()
        }
        break

      case MessageTypes.GQL_COMPLETE: {
        const handler = this.operations[opId].handler
        delete this.operations[opId]
        // @ts-ignore
        handler.call(this, null, null)
        break
      }
      case MessageTypes.GQL_ERROR:
        // @ts-ignore
        this.operations[opId].handler(this.formatErrors(parsedMessage.payload), null)
        delete this.operations[opId]
        break

      case MessageTypes.GQL_DATA: {
        const parsedPayload = !parsedMessage.payload.errors
        // @ts-ignore
          ? parsedMessage.payload : { ...parsedMessage.payload, errors: this.formatErrors(parsedMessage.payload.errors) }
        // @ts-ignore
        this.operations[opId].handler(null, parsedPayload)
        break
      }

      case MessageTypes.GQL_CONNECTION_KEEP_ALIVE: {
        // @ts-ignore
        const firstKA = typeof this.wasKeepAliveReceived === 'undefined'
        // @ts-ignore
        this.wasKeepAliveReceived = true

        if (firstKA) {
          // @ts-ignore
          this.checkConnection()
        }

        // @ts-ignore
        if (this.checkConnectionIntervalId) {
          // @ts-ignore
          clearInterval(this.checkConnectionIntervalId)
          // @ts-ignore
          this.checkConnection()
        }
        // @ts-ignore
        this.checkConnectionIntervalId = setInterval(this.checkConnection.bind(this), this.wsTimeout)
        break
      }

      default:
        throw new Error('Invalid message type!')
    }
  }
}
