import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import { Integrations } from '@sentry/tracing'

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    debug: process.env.NODE_ENV !== 'production',
    integrations: [new Integrations.BrowserTracing()],
    Vue,
    release: `${INFO.branch
    }:${INFO.version
    }:${INFO.abbreviatedSha
    }:${INFO.dateBuild
    }`,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
    tracingOptions: {
      trackComponents: true
    },
    attachProps: true
  })
}

export default Sentry
