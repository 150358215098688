import router, { RouteNames } from '@/router'
import Sentry from '../sentry'
import logsModules from '@/store/logs'
import { onError } from '@apollo/client/link/error'
import connectionModule, { HttpStatus } from '@/store/connection'

export const errorLink = onError((error) => {
  const context = error.operation.getContext()
  if (error.networkError) {
    connectionModule.mutations.setStatusHttp({
      name: context.uri || 'unknown',
      status: HttpStatus.Error
    })
  }
  logsModules.mutations.addError(error)

  const exception = error.graphQLErrors?.[0]?.message || error.networkError?.message
  Sentry.captureException(exception, {
    // в sentry "extra" почему то "unknown", а в документации "Additional context for this event. Must be a mapping. Children can be any native JSON type."
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extra: error
  })
  if (error.graphQLErrors?.find(e => e.extensions?.code === 'UNAUTHORIZED_ERROR')) {
    router.push({ name: RouteNames.development })
  }
})
