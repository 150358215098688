import { terminalService } from '@/store/terminalService'
import connection, { WebSocketStatus } from '@/store/connection'
import { settingsModule } from '@/store/settings'
import { logBreadcrumb } from '@/core/logger'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { LinkConfigurator } from '@/plugins/apollo/LinkConfigurator'

const log = (...arg:any[]) => logBreadcrumb({ tag: 'apollo default', color: 'red' }, ...arg)

const name = 'ws server'

const linkConfigurator = new LinkConfigurator({
  async http() {
    return (await settingsModule.actions.publicSettings())?.GRAPHQL_HTTP ||
      'http://localhost:4000/graphql'
  },
  async ws() {
    return (await settingsModule.actions.publicSettings())?.GRAPHQL_WS ||
      'ws://localhost:4000/graphql'
  },
  wsHandlers: {
    onConnecting: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Connecting
      })
    },
    onConnected: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Connected
      })
    },
    onReconnecting: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Reconnecting
      })
    },
    onReconnected: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Reconnected
      })
    },
    onDisconnected: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Disconnected
      })
    },
    onError: () => {
      connection.mutations.setStatusWebSocket({
        name,
        status: WebSocketStatus.Error
      })
    }
  },
  subscriptionOptions: {
    reconnect: true,
    async connectionParams() {
      try {
        const mac = await terminalService.getMAC()
        return { mac }
      } catch (e) {
        log('default client', { error: e })
      }
    }
  },
  async httpHeaders() {
    const mac = await terminalService.getMAC()
    return {
      mac
    }
  }
})

export const apolloClient = new ApolloClient({
  link: linkConfigurator.link,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production'
})
