import { createModule } from 'vuexok'
import store from '@/store/index'
import { terminalService } from '@/store/terminalService'
import { SubscribeOptions } from '@/core/subscribeHelpers'
import { Maybe } from 'graphql/jsutils/Maybe'
import { apolloClient } from '@/plugins/apollo/default'
import { waitTimer } from '@/core/pendings'
import { logBreadcrumb } from '@/core/logger'
import { ObservableQuery } from '@apollo/client'
import {
  productSubcategory,
  productSubcategoryAdded,
  ProductSubcategoryAddedSubscription,
  ProductSubcategoryAddedSubscriptionVariables, productSubcategoryDeleted,
  ProductSubcategoryDeletedSubscription,
  ProductSubcategoryDeletedSubscriptionVariables,
  ProductSubcategoryQuery,
  ProductSubcategoryQueryVariables,
  productSubcategoryUpdated,
  ProductSubcategoryUpdatedSubscription,
  ProductSubcategoryUpdatedSubscriptionVariables, SubcategoryFragment
} from '@/graphql/default/category.graphql'
import { cloneDeep } from 'lodash-es'

type State = {
  productSubcategory: ProductSubcategoryQuery['productSubcategory']
}

const tag = 'categoryModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'blue' }, ...arg)

const stateInit = ():State => ({
  productSubcategory: null
})

let observableQuerySubcategory: ObservableQuery<ProductSubcategoryQuery, ProductSubcategoryQueryVariables> | undefined

export const categoryModule = createModule(tag, {
  namespaced: true,
  state: stateInit,
  mutations: {
    setProductSubcategory(s, productSubcategory: ProductSubcategoryQuery['productSubcategory']) {
      log('Запоминаем подкатегории', { productSubcategory })
      s.productSubcategory = cloneDeep(productSubcategory)
    }
  },
  actions: {
    async startWatchingSubscriptionsAndQueries() {
      log('Запускаем процесс отслеживания состояние категорий')
      const timeoutBetweenRetries = 60e3
      try {
        if (observableQuerySubcategory) {
          log('Обновляем подкатегории')
          await observableQuerySubcategory.refetch()
        } else {
          log('Подписываемся на подкатегории')
          const mac = await terminalService.getMAC()
          const subscribeVariables = {
            mac
          }

          observableQuerySubcategory = apolloClient
            .watchQuery<ProductSubcategoryQuery, ProductSubcategoryQueryVariables>({
              query: productSubcategory,
              errorPolicy: 'all'
            })
          observableQuerySubcategory.subscribe(
            ({ data: { productSubcategory } }) => {
              categoryModule.mutations.setProductSubcategory(productSubcategory)
            },
            () => {
              waitTimer(timeoutBetweenRetries)
                .catch(categoryModule.actions.startWatchingSubscriptionsAndQueries)
            }
          )
          observableQuerySubcategory.subscribeToMore(new SubscribeOptions<ProductSubcategoryQuery,
            ProductSubcategoryAddedSubscription,
            ProductSubcategoryAddedSubscriptionVariables,
            ProductSubcategoryQueryVariables>({
              type: 'added',
              subscribeDocument: productSubcategoryAdded,
              queryDocument: productSubcategory,
              variables: subscribeVariables
            }))
          observableQuerySubcategory.subscribeToMore(new SubscribeOptions<ProductSubcategoryQuery,
            ProductSubcategoryUpdatedSubscription,
            ProductSubcategoryUpdatedSubscriptionVariables,
            ProductSubcategoryQueryVariables>({
              type: 'updated',
              subscribeDocument: productSubcategoryUpdated,
              queryDocument: productSubcategory,
              variables: subscribeVariables
            }))
          observableQuerySubcategory.subscribeToMore(new SubscribeOptions<ProductSubcategoryQuery,
            ProductSubcategoryDeletedSubscription,
            ProductSubcategoryDeletedSubscriptionVariables,
            ProductSubcategoryQueryVariables>({
              type: 'deleted',
              subscribeDocument: productSubcategoryDeleted,
              queryDocument: productSubcategory,
              variables: subscribeVariables
            }))
        }
      } catch {
        waitTimer(timeoutBetweenRetries)
          .catch(categoryModule.actions.startWatchingSubscriptionsAndQueries)
      }
    },
    /**
     * Получаем Subcategory
     */
    async queryProductSubcategory() {
      log('Запрашиваем подкатегории')
      await apolloClient.query<ProductSubcategoryQuery, ProductSubcategoryQueryVariables>({
        query: productSubcategory,
        fetchPolicy: 'network-only'
      })
    }
  },
  getters: {
    productSubcategory(state): Maybe<SubcategoryFragment[]> {
      return state.productSubcategory?.edges
        .map(edge => edge?.node).filter((v) => !!v) as Maybe<SubcategoryFragment[]>
    },
    newProductCategory(state) {
      return state.productSubcategory?.edges
        .find(edge => edge?.node?.title?.toLowerCase().includes('новинки'))
    }
  }
})

categoryModule.register(store)

export default categoryModule
