import Sentry from '@/plugins/sentry'
import { Console } from 'console'

export function logBreadcrumb({ tag, color }:{
  tag: string,
  color?:string
}, ...arg:Parameters<Console['debug']>) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.addBreadcrumb({
      category: tag,
      message: JSON.stringify(arg),
      level: Sentry.Severity.Log,
      data: { arg }
    })
  }
  console.debug(`%c${tag}`,
    'display:inline-block;' +
      'padding:2px;' +
      'border-radius:5px;' +
      'color:white;' +
      `background:${color ?? 'green'};`, ...arg)
}

interface LoggerParams {
  type?: 'log' | 'trace' | 'warn' | 'info' | 'debug';
  inputs?: boolean;
  outputs?: boolean;
}

const defaultParams: Required<LoggerParams> = {
  type: 'debug',
  inputs: true,
  outputs: true
}

export function Log(p?: LoggerParams) {
  const params = { ...p, ...defaultParams }
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const targetMethod = descriptor.value

    // eslint-disable-next-line no-param-reassign,func-names
    descriptor.value = function(...args: any[]) {
      if (params.inputs) {
        console[params.type](`[log input] ${target?.constructor?.name}.${propertyKey}()`, ...args)
      }
      const output = targetMethod.apply(this, args)
      if (params.outputs) {
        if (output instanceof Promise) {
          return new Promise((resolve, reject) => {
            output.then((result) => {
              console[params.type](
                `[log output] ${target?.constructor?.name}.${propertyKey}() `,
                ...args, ' => ', result
              )
              resolve(result)
            })
              .catch(reject)
          })
        }
      }
      console[params.type](
        `[log output] ${target?.constructor?.name}.${propertyKey}() `,
        ...args, ' => ', output)
      return output
    }

    return descriptor
  }
}
