












import { Component, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'

@Component({
  components: { BSpinner }
})
export default class App extends Vue {
  get loading() {
    return false
  }
}
