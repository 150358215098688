import { ApolloLink, from, HttpLink, split } from '@apollo/client/core'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { ListenerFn } from 'eventemitter3'
import { setContext } from '@apollo/client/link/context'
import { ClientOptions } from 'subscriptions-transport-ws/dist/client'
import { errorLink } from '@/plugins/apollo/errorLink'
import { retryLink } from '@/plugins/apollo/retryLink'
import { cloneDeep, merge } from 'lodash-es'
import connectionModule, { HttpStatus } from '@/store/connection'
import {
  SubscriptionClient
} from '@/plugins/apollo/ModifiedSubscriptionClient'

export type WsHandlers = {
  onConnected: ListenerFn;
  onConnecting: ListenerFn;
  onDisconnected: ListenerFn;
  onReconnected: ListenerFn;
  onReconnecting: ListenerFn;
  onError: ListenerFn;
}

export type FunctionLink = string | (() => string) | (() => Promise<string>)
export type Headers = Record<string, string>
  | (()=>Record<string, string>)
  | (() => Promise<Record<string, string>>)

export type LinkOptions = {
  http: FunctionLink,
  ws?: FunctionLink,
  wsHandlers?: Partial<WsHandlers>,
  subscriptionOptions?: ClientOptions,
  httpHeaders?: Headers,
}

export class LinkConfigurator {
  private readonly options: LinkOptions
  private _subscriptionClient?: SubscriptionClient
  public readonly link: ApolloLink;

  constructor(options:LinkOptions) {
    this.options = cloneDeep(options)
    this.link = this.createMainLink()

    if (this.options.wsHandlers) {
      this.setWsHandlers(this.options.wsHandlers)
    }
  }

  private get subscriptionClient(): SubscriptionClient|undefined {
    return this._subscriptionClient
  }

  private set subscriptionClient(value: SubscriptionClient|undefined) {
    this._subscriptionClient = value
    if (this.options.wsHandlers) {
      this.setWsHandlers(this.options.wsHandlers)
    }
  }

  private get httpLink() {
    return setContext(async() => {
      const { http, httpHeaders } = this.options
      let context = {}
      if (typeof http === 'function') {
        context = merge(context, { uri: await http() })
      } else {
        context = merge(context, { uri: http })
      }
      if (typeof httpHeaders === 'function') {
        context = merge(context, { headers: await httpHeaders() })
      } else {
        context = merge(context, { headers: httpHeaders })
      }
      return context
    })
      .concat((operation, forward) => {
        const context = operation.getContext()
        const name = (typeof context.uri === 'string' && context.uri) || 'unknown'
        return forward(operation).map(data => {
          connectionModule.mutations.setStatusHttp({
            name,
            status: HttpStatus.Ok
          })
          return data
        })
      })
      .concat(new HttpLink())
  }

  private async createSubscriptionClient(ws: FunctionLink): Promise<void> {
    if (ws && !this.subscriptionClient) {
      const clientOptions:ClientOptions = merge<ClientOptions, ClientOptions|undefined>(
        {
          reconnect: true,
          timeout: 16e3 // время ожидания keep alive от сервера для проверки соединения
        },
        this.options.subscriptionOptions
      )
      if (typeof ws === 'string') {
        this.subscriptionClient = new SubscriptionClient(ws, clientOptions)
      } else {
        const lazyWs = await ws()
        if (!this.subscriptionClient) {
          this.subscriptionClient = new SubscriptionClient(lazyWs, clientOptions)
        } else {
          await this.changeWs(lazyWs)
        }
      }
    } else if (ws && this.subscriptionClient) {
      await this.changeWs(ws)
    }
  }

  private async changeWs(ws:FunctionLink) {
    let url: string
    if (typeof ws === 'string') {
      url = ws
    } else {
      url = await ws()
    }

    // используем обходные пути для смены адреса ws
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const oldUrl = this.subscriptionClient?.url
    if (oldUrl !== url && this.subscriptionClient) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.subscriptionClient.url = url
      this.subscriptionClient.close(false)
    }
  }

  setWsHandlers(wsHandlers:Partial<WsHandlers>) {
    return (Object.entries(wsHandlers) as [K:keyof WsHandlers, H:ListenerFn][])
      .reduce((prev:Partial<Record<keyof WsHandlers, ()=>void>>, [key, handler]) => {
        return Object.assign(prev, {
          [key]: this.subscriptionClient?.[key](handler)
        })
      }, {})
  }

  private get wsLink() {
    if (this.subscriptionClient instanceof SubscriptionClient) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return new WebSocketLink(this.subscriptionClient)
    } else {
      return new ApolloLink(() => {
        throw new Error('Не задан WS для подписок')
      })
    }
  }

  private createMainLink() {
    let link = from([
      errorLink,
      retryLink,
      split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        },
        (operation, forward) => this.wsLink.request(operation, forward),
        this.httpLink
      )
    ])

    link = setContext(async() => {
      if (this.options.ws) {
        await this.createSubscriptionClient(this.options.ws)
      }
    }).concat(link)

    return link
  }

  get ws() {
    return this.options.ws
  }

  set ws(ws:FunctionLink|undefined) {
    if (ws && this.options.ws !== ws) {
      this.changeWs(ws)
      this.options.ws = ws
    }
  }

  get http() {
    return this.options.http
  }

  set http(http) {
    this.options.http = http
  }
}
