import { debounce } from 'lodash-es'

export function asyncDebounce<
  F extends(...args: any[]) => Promise<any>
  >(func: F, wait?: number, options?: Parameters<typeof debounce>[2]) {
  let resolveSet /* = new Set<(...p:any)=>void>() */ = <((...p:any)=>void)[]>[]
  let rejectSet /* = new Set<(...p:any)=>void>() */ = <((...p:any)=>void)[]>[]

  const debounced = debounce((args: Parameters<F>) => {
    func(...args)
      .then((...res) => {
        resolveSet.reverse().forEach((resolve) => resolve(...res))
        resolveSet = []
      })
      .catch((...res) => {
        rejectSet.reverse().forEach((reject) => reject(...res))
        rejectSet = []
      })
  }, wait, options)

  return (...args: Parameters<F>): ReturnType<F> => new Promise((resolve, reject) => {
    resolveSet.push(resolve)
    rejectSet.push(reject)
    debounced(args)
  }) as ReturnType<F>
}
