import { createModule } from 'vuexok'
import store from '@/store/index'
import router, { RouteNames } from '@/router'
import { waitForUser } from '@/core/pendings'
import { logBreadcrumb } from '@/core/logger'
import { debounce } from 'lodash-es'
import { updaterModule } from '@/store/updater'
import Sentry from '@/plugins/sentry'
import { settingsModule } from '@/store/settings'
import { operator } from '@/store/operator'

type State = {
  /**
   * Определяет наличие пользователя перед терминалом
   */
  userHere: boolean
}

const tag = 'userTrackerModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'gray' }, ...arg)

const state = ():State => ({
  userHere: false
})

const userPresenceEvents = ['click', 'scroll', 'contextmenu', 'keypress', 'mousemove',
  'mousewheel', 'touchstart', 'touchend', 'touchcancel']

export const userTrackerModule = createModule(tag, {
  namespaced: true,
  state,
  mutations: {
    userCome: (state) => {
      log('Пользователь активен')
      state.userHere = true
    },
    userLeft(state) {
      state.userHere = false
    }
  },
  actions: {
    /**
     * Запускает отслеживание пользователя
     */
    async startWatchingUser() {
      log('Запускаем отслеживание присутствия пользователя')
      await userTrackerModule.actions.bindUserPresenceHandlers()
    },
    /**
     * Запуск процессов в режиме ожидания
     */
    async idleTasks() {
      log('Запускаем сценарий в режиме ожидания')
      Sentry.captureMessage('Перешли в режим ожидания')
      try {
        await Promise.all([
          // редирект пользователя на главную
          router.push({ name: RouteNames.index })
        ])
      } finally {
        // обновляем приложение
        await updaterModule.actions.startUpdate()
      }
    },
    /**
     * Связываем обработчики событий присутствия пользователя
     */
    async bindUserPresenceHandlers() {
      log('Добавляем обработчики событий присутствия пользователя')
      userPresenceEvents.forEach(type => {
        window.addEventListener(type, userComeDebounce)
      })
    },
    /**
     * Снимаем обработку событий
     */
    async unbindUserPresenceHandlers() {
      log('Снимаем обработчики событий присутствия пользователя')
      userPresenceEvents.forEach(type => {
        window.removeEventListener(type, userComeDebounce)
      })
    }
  }
})

export const userComeDebounce = debounce(
  userTrackerModule.mutations.userCome,
  1e3, { maxWait: 5e3 }
)

userTrackerModule.register(store)

userTrackerModule.watch((state) => state.userHere,
  async(userHere, oldUserHere) => {
    log('Обрабатываем изменение присутствия пользователя', { userHere, oldUserHere })
    // пришёл
    if (!oldUserHere && userHere) {
      log('Пользователь пришёл')
      await waitForUser(settingsModule.state.publicSettings.idleTime, userPresenceEvents)
      userTrackerModule.mutations.userLeft()
    }
    // ушёл
    if (oldUserHere && !userHere) {
      log('Пользователь ушёл')
      if (!settingsModule.state.publicSettings.disableIdleTasks && !operator.operatorId) {
        await userTrackerModule.actions.idleTasks()
      }
    }
  })

userTrackerModule.actions.startWatchingUser()
