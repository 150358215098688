import { RetryLink } from '@apollo/client/link/retry'
import { Operation } from '@apollo/client/link/core'

// настройка повтора запросов
export const retryLink = new RetryLink({
  delay: {
    initial: 1e3, // e3 - секунда
    max: 60e3,
    jitter: true
  },
  attempts(count: number, { operationName }: Operation, error: any): boolean {
    return !!error && (
    // важные операции не перестаём повторять
      ['payCart'].includes(operationName) ||
      // повторяем операции с ошибкой 5 раз
      count <= 5)
  }
})
