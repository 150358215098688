import { debounce } from 'lodash-es'
const eventTypes = ['click', 'scroll', 'contextmenu', 'keypress', 'mousemove', 'mousewheel', 'touchstart', 'touchend', 'touchcancel']
export const waitForUser = (waitingTime:number, types = eventTypes): Promise<void> => new Promise((resolve) => {
  const handler = debounce(() => {
    types.forEach(type => {
      window.removeEventListener(type, handler)
    })
    resolve()
  }, waitingTime)
  types.forEach(type => {
    window.addEventListener(type, handler)
  })
})

export const waitTimer = (timeOut:number, stepTimeout = 1e3, ruleToStop?:()=>boolean): Promise<void> => new Promise((resolve, reject) => {
  const interval = setInterval(() => {
    const isStop = ruleToStop?.()
    if (isStop) {
      clearInterval(interval)
      clearTimeout(timeout)
      resolve()
    }
  }, stepTimeout)
  const timeout = setTimeout(() => {
    clearInterval(interval)
    clearTimeout(timeout)
    reject(new Error('Время вышло'))
  }, timeOut)
})
