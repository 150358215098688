export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum ErrorTypes {
  ServerError = 'SERVER_ERROR',
  NotFoundError = 'NOT_FOUND_ERROR',
  ValidationError = 'VALIDATION_ERROR',
  PaymentError = 'PAYMENT_ERROR',
  InsufficientFundsError = 'INSUFFICIENT_FUNDS_ERROR',
  ReadScannerError = 'READ_SCANNER_ERROR',
  ValidateCardError = 'VALIDATE_CARD_ERROR',
  ScannerTimeoutError = 'SCANNER_TIMEOUT_ERROR',
  MacAddressError = 'MAC_ADDRESS_ERROR'
}

export type GenerateNalunchQrCode = {
  __typename?: 'GenerateNalunchQrCode';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Ссылка для qr кода */
  qrCode?: Maybe<Scalars['String']>;
};

export type ItemUnit = {
  price?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Выполнение платежа и возврат платежной транзакции */
export type MakePayment = {
  __typename?: 'MakePayment';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** ID транзакции */
  transactionNumber?: Maybe<Scalars['String']>;
};

/** Осуществить платеж */
export type Mutations = {
  __typename?: 'Mutations';
  /** Послать штрихкод от сканера */
  sendBarcode?: Maybe<SaveData>;
  /** Послать код карты программы лояльности */
  sendLoyaltyCardCode?: Maybe<SaveData>;
  /** Послать код сотрудника */
  sendPersonalCode?: Maybe<SaveData>;
  /** Послать код упаковки */
  sendPackCode?: Maybe<SaveData>;
  /** Послать оплаты пользователя */
  sendUserCode?: Maybe<SaveData>;
  /** Послать код Обед.ру */
  sendObedRuCode?: Maybe<SaveData>;
  /** Выполнение платежа и возврат платежной транзакции */
  makePayment?: Maybe<MakePayment>;
  /** Генерация qr кода NaLunch */
  generateNalunchQrCode?: Maybe<GenerateNalunchQrCode>;
  cancelPayment?: Maybe<MakePayment>;
  /**
   * Передача авторизационных данных Foodcard
   * @deprecated Поле `cacheBoxId` устарело! Используйте `authId`
   */
  saveFoodcardAuthData?: Maybe<SaveData>;
  /** Передача авторизационных данных для YandexGO */
  saveYandexGOAuthData?: Maybe<SaveData>;
  /** Передача авторизационных данных для NaLunch */
  saveNaLunchAuthData?: Maybe<SaveData>;
  /** Передача авторизационных данных для Yandex Badge */
  saveYandexBadgeAuthData?: Maybe<SaveData>;
  /** Передача авторизационных данных для ОбедРу */
  saveObedRuAuthData?: Maybe<SaveData>;
  /** Отправка ассортимента для текущего терминала в YandexMenu */
  saveToYandexGOMenu?: Maybe<SaveData>;
};


/** Осуществить платеж */
export type MutationsSendBarcodeArgs = {
  barcode: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSendLoyaltyCardCodeArgs = {
  code: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSendPersonalCodeArgs = {
  personalCode: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSendPackCodeArgs = {
  packCode: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSendUserCodeArgs = {
  userCode: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSendObedRuCodeArgs = {
  code: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsMakePaymentArgs = {
  cartId: Scalars['ID'];
  items?: Maybe<Array<Maybe<ItemUnit>>>;
  price?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  provider?: Maybe<Provider>;
};


/** Осуществить платеж */
export type MutationsGenerateNalunchQrCodeArgs = {
  cartId: Scalars['ID'];
  price?: Maybe<Scalars['Float']>;
};


/** Осуществить платеж */
export type MutationsCancelPaymentArgs = {
  cartId: Scalars['ID'];
  provider?: Maybe<Provider>;
};


/** Осуществить платеж */
export type MutationsSaveFoodcardAuthDataArgs = {
  authId?: Maybe<Scalars['ID']>;
  cashBoxId?: Maybe<Scalars['ID']>;
  authToken: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSaveYandexGoAuthDataArgs = {
  authToken: Scalars['String'];
  mcc: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSaveNaLunchAuthDataArgs = {
  authToken: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSaveYandexBadgeAuthDataArgs = {
  authToken: Scalars['String'];
};


/** Осуществить платеж */
export type MutationsSaveObedRuAuthDataArgs = {
  authToken: Scalars['String'];
  authId: Scalars['String'];
};

export enum Provider {
  Sberbank = 'SBERBANK',
  Psbank = 'PSBANK',
  Tbank = 'TBANK',
  Foodcard = 'FOODCARD',
  Yandexgo = 'YANDEXGO',
  Nalunch = 'NALUNCH',
  Yandexbadge = 'YANDEXBADGE',
  Obedru = 'OBEDRU'
}

export type Query = {
  __typename?: 'Query';
  /** MAC адреса терминала. */
  macAddress?: Maybe<Scalars['String']>;
  /** Список штрихкодов (нужна для работы подписки на фронте) */
  barcodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Текущее состояние системы */
  systemState?: Maybe<Array<Maybe<SystemState>>>;
  /** Номер транзакции по ID корзины */
  transactionByCartId?: Maybe<Scalars['String']>;
};


export type QueryTransactionByCartIdArgs = {
  globalCartId: Scalars['String'];
};

export type SaveData = {
  __typename?: 'SaveData';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
};

export enum SystemState {
  AllOk = 'ALL_OK',
  ServiceNotConfigured = 'SERVICE_NOT_CONFIGURED',
  FoodcardDataNotFound = 'FOODCARD_DATA_NOT_FOUND',
  AuthDataNotFound = 'AUTH_DATA_NOT_FOUND',
  YandexDataNotFound = 'YANDEX_DATA_NOT_FOUND',
  NalunchDataNotFound = 'NALUNCH_DATA_NOT_FOUND',
  YandexBadgeDataNotFound = 'YANDEX_BADGE_DATA_NOT_FOUND',
  ObedRuDataNotFound = 'OBED_RU_DATA_NOT_FOUND'
}

export type WeSubscriptions = {
  __typename?: 'WESubscriptions';
  /** Подписка на получение штрих-кодов */
  barcodeAdded: Barcode;
  loyaltyCardCodeAdded: Code;
  personalCodeAdded: Code;
  packCodeAdded: Code;
  yandexCodeAdded: Code;
};

export type Barcode = {
  __typename?: 'barcode';
  barcode: Scalars['String'];
};

export type Code = {
  __typename?: 'code';
  code: Scalars['String'];
};
