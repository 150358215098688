import { createModule } from 'vuexok'
import store from '.'
import { apolloClient } from '@/plugins/apollo/default'
import { Maybe } from 'graphql/jsutils/Maybe'
import { SubscribeOptions } from '@/core/subscribeHelpers'
import { terminalService } from '@/store/terminalService'
import { logBreadcrumb } from '@/core/logger'
import { settingsModule } from '@/store/settings'
import { ObservableQuery } from '@apollo/client/core'
import {
  terminal,
  TerminalQuery,
  TerminalQueryVariables,
  terminalUpdated,
  TerminalUpdatedSubscription,
  TerminalUpdatedSubscriptionVariables,
  terminals,
  TerminalsQuery,
  TerminalsQueryVariables,
  AdSlideFragment
} from '@/graphql/default/terminal.graphql'
import { cloneDeep } from 'lodash-es'
import router, { RouteNames } from '@/router'
import { State, Getters } from '@/store/TerminalTypes'
import { Provider } from '@/graphql/terminal/graphql.schema'
import { PaymentProviderTitle } from '@/graphql/default/graphql.schema'

const tag = 'terminalModule'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...arg: any[]) => logBreadcrumb({ tag, color: 'blue' }, ...arg)

const state: State = {
  terminal: null,
  terminals: null,
  paymentErrors: null
}

let observableQuery: Maybe<ObservableQuery<TerminalQuery, TerminalQueryVariables>> = null

export const terminalModule = createModule(tag, {
  namespaced: true,
  state,
  mutations: {
    setTerminal(state, terminal: State['terminal']) {
      log('запоминаем Terminal', terminal)
      state.terminal = cloneDeep(terminal)
    },
    setTerminals(state, terminals: State['terminals']) {
      state.terminals = cloneDeep(terminals)
    },
    setPaymentErrors(state, errors) {
      if (errors) log('сохраняем ошибки оплаты', errors)
      state.paymentErrors = cloneDeep(errors)
    }
  },

  actions: {
    async startWatchingSubscriptionsAndQueries() {
      log('Запускаем отслеживание состояния')
      observableQuery = apolloClient.watchQuery<TerminalQuery, TerminalQueryVariables>({
        query: terminal,
        errorPolicy: 'all',
        pollInterval: settingsModule.state.publicSettings.terminalSettingsUpdateInterval
      })
      observableQuery.subscribe(({ data: { terminal } }) => {
        terminalModule.mutations.setTerminal(terminal)
      })
      observableQuery.subscribeToMore(new SubscribeOptions<TerminalQuery,
        TerminalUpdatedSubscription,
        TerminalUpdatedSubscriptionVariables,
        TerminalQueryVariables>({
          type: 'updated',
          subscribeDocument: terminalUpdated,
          queryDocument: terminal,
          variables: {
            macAddress: await terminalService.getMAC()
          }
        }))
    },

    async getTerminalList() {
      try {
        const result = await apolloClient.query<TerminalsQuery, TerminalsQueryVariables>({
          query: terminals
        })
        terminalModule.mutations.setTerminals(result.data?.terminals)
      } catch (e) {
        console.error(e)
        return false
      }
    }
  },

  getters: {
    terminal(state): Getters['terminal'] {
      return state.terminal?.edges[0]?.node
    },

    activePaymentProvider(state): Provider | PaymentProviderTitle {
      const payment = state.terminal?.edges[0]?.node?.paymentProviders?.edges
        .filter(item => item?.node?.active && item?.node?.required)
      return payment?.[0]?.node?.title || PaymentProviderTitle.Sberbank
    },

    terminals(state): Getters['terminals'] {
      return state.terminals?.edges?.map(item => {
        const node = item?.node
        if (node) {
          return {
            ...node
          }
        }
        return null
      })
    },
    saleTerminals(state): Getters['terminals'] {
      return state.terminals?.edges
        .filter(item => item?.node?.isSalesPoint)
        .map(item => {
          const node = item?.node
          if (node) {
            return {
              ...node
            }
          }
          return null
        })
    },
    paymentErrors(state) {
      return state.paymentErrors
    },
    logo(state, getters: Getters): Getters['logo'] {
      const node = getters.terminal?.logos.edges[0]?.node
      if (node) {
        return {
          ...node,
          image: settingsModule.getters.mediaPath + node.image
        }
      }
      return null
    },
    banners(state, getters: Getters): Getters['banners'] {
      return getters.terminal?.banners?.edges?.map(edge => {
        const node = edge?.node
        if (node) {
          return {
            ...node,
            image: settingsModule.getters.mediaPath + node.image
          }
        }
        return null
      })
    },
    foodCardAuthData(state, getters: Getters): Getters['foodCardAuthData'] {
      return getters.terminal?.foodcardAuthData
    },
    yandexGoAuthData(state, getters: Getters): Getters['yandexAuthData'] {
      return getters.terminal?.yandexAuthData
    },
    yandexBagdeAuthData(state, getters: Getters): Getters['yandexBadgeAuthData'] {
      return getters.terminal?.yandexBadgeAuthData
    },
    sberbankAuthData(state, getters: Getters): Getters['sberbankAuthData'] {
      return getters.terminal?.sberbankAuthData
    },
    nalunchAuthData(state, getters: Getters): Getters['nalunchAuthData'] {
      return getters.terminal?.nalunchAuthData
    },
    obedRuAuthData(state, getters: Getters): Getters['obedRuAuthData'] {
      return getters.terminal?.obedRuAuthData
    },
    adSlideList(state, getters: Getters): Getters['adSlideList'] {
      return getters.terminal?.bannersScripts?.reduce<AdSlideFragment[]>((acc, adBanner) => {
        if (adBanner) {
          const slides: AdSlideFragment[] | undefined = adBanner.slides
            ?.reduce<AdSlideFragment[]>((acc, slide) => {
              if (slide) {
                return [...acc, slide]
              }
              return acc
            }, [])
          if (slides) {
            return [...acc, ...slides]
          }
        }
        return acc
      }, [])
    }
  }
})

terminalModule.register(store)

terminalModule.watch((state, getters) => getters.foodCardAuthData,
  (foodCardAuthData) => {
    log('Обрабатываем изменение foodCardAuthData', foodCardAuthData)
    if (foodCardAuthData) {
      const { authId, authToken } = foodCardAuthData
      terminalService.saveFoodcardAuthData({ authToken, authId })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.yandexGoAuthData,
  (yandexGoAuthData) => {
    log('Обрабатываем изменение yandexGoAuthData', yandexGoAuthData)
    if (yandexGoAuthData) {
      const { authToken, mcc } = yandexGoAuthData
      terminalService.saveYandexGoAuthData({ authToken, mcc })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.yandexBagdeAuthData,
  (yandexBadgeAuthData) => {
    log('Обрабатываем изменение yandexBagdeAuthData', yandexBadgeAuthData)
    if (yandexBadgeAuthData) {
      const { authToken } = yandexBadgeAuthData
      terminalService.saveYandexBadgeAuthData({ authToken })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.nalunchAuthData,
  (nalunchAuthData) => {
    log('Обрабатываем изменение nalunchAuthData', nalunchAuthData)
    if (nalunchAuthData) {
      const { authToken } = nalunchAuthData
      terminalService.saveNaLunchAuthData({ authToken })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.obedRuAuthData,
  (obedRuAuthData) => {
    log('Обрабатываем изменение obedRuAuthData', obedRuAuthData)
    if (obedRuAuthData) {
      const { authToken, authId } = obedRuAuthData
      terminalService.saveObedRuAuthData({ authToken, authId })
    }
  }, { deep: true, immediate: true })

terminalModule.watch(
  (state1, getters) => getters.terminal?.isDemoMode,
  (isDemoMode) => {
    if (isDemoMode && router.currentRoute.name === RouteNames.lock) {
      router.push({ name: RouteNames.catalog })
    }
  },
  { immediate: true }
)

export default terminalModule
