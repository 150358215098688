import { createModule } from 'vuexok'
import { logBreadcrumb as logger } from '@/core/logger'
import { apolloClient } from '@/plugins/apollo/default'
import { v4 } from 'uuid'
import { clone, cloneDeep, union, uniqBy } from 'lodash-es'
import store from '@/store'
import {
  CartStatus,
  ItemsInputType,
  PaymentTypes,
  Scalars,
  InventoryActionTypes
} from '@/graphql/default/graphql.schema'
import {
  applyLoyaltyCart,
  ApplyLoyaltyCartMutation,
  ApplyLoyaltyCartMutationVariables,
  payCart as payCartGraphql,
  PayCartMutation,
  PayCartMutationVariables,
  inventoryCart as inventoryCartGraphql,
  InventoryCartMutation,
  InventoryCartMutationVariables
} from '@/graphql/default/carts.graphql'
import { ApolloError } from '@apollo/client/core'
import { CartInterface, State } from '@/store/cart/CartTypes'
import { ErrorTypes, ItemUnit, Provider } from '@/graphql/terminal/graphql.schema'
import { getters } from '@/store/cart/getters'
import Sentry from '@/plugins/sentry'
import { CartError, ServerErrors } from '@/store/cart/serverErrors'
import { customer } from '@/store/customer'
import { operator } from '@/store/operator'
import {
  deactualizeCart,
  getNewServerCart,
  getReceipt,
  setWaitPayment
} from '@/store/cart/cartAPI'
import terminal from '@/store/terminal'

const terminalServiceModule = () =>
  import('@/store/terminalService').then(
    ({ terminalService }) => terminalService
  )

const TAG = 'localCart'
const log = (...arg: Parameters<Console['log']>) =>
  logger({ tag: TAG, color: '#DA9BAD' }, ...arg)

const setupCartModule = () => {
  const stateInit = (): State => ({
    items: [],
    isShowedYandexCodeText: false,
    isShowedObedRuCodeText: false,
    itemsHistory: [],
    provider: terminal.getters.activePaymentProvider,
    promoCodes: [],
    deletedItems: 0
  })

  const vuexModule = createModule(TAG, {
    namespaced: true,
    state: stateInit,
    mutations: {

      /**
       * Записываем состав корзины
       * @param state
       * @param items
       */
      setItems(state, items: State['items']) {
        log('Записываем состав корзины', items)
        const oldItems = clone(state.items)
        state.items = uniqBy(items, 'productId').filter(({ count }) => !!count)
        handlerItems(state.items, oldItems)
      },

      /**
       * Записать id продуктов в историю
       * @param s
       * @param items
       */
      pushItemsHistory(s, items: State['itemsHistory']) {
        log('Записываем id продуктов в историю', items)
        s.itemsHistory = union(s.itemsHistory, items)
      },

      /**
       * Переписать промокоды
       * @param s
       * @param promoCodes
       */
      setPromoCodes(s, promoCodes: State['promoCodes']) {
        log('Запоминаем промокоды', promoCodes)
        s.promoCodes = promoCodes
      },

      /*
        * Увеличиваем количество удаленных из корзины товаров
       */
      increaseDeletedItems(s) {
        s.deletedItems = s.deletedItems + 1
      },

      /*
        * Сбрасываем количество удаленных из корзины товаров
       */
      resetDeletedItems(s) {
        s.deletedItems = 0
      },

      /**
       * Сбрасываем состояние модуля
       * @param s
       */
      resetState(s) {
        log('Сбрасываем состояние модуля')
        const emptyState = stateInit()
        Object.assign(s, emptyState)
      },

      /**
       * Устанавливаем состояние отображения текста о сканировании кода yandex
       * @param s
       * @param flag
       */
      setShowYandexCodeText(s, flag: boolean) {
        s.isShowedYandexCodeText = flag
      },

      /**
       * Устанавливаем состояние отображения текста о сканировании кода обед.ру
       * @param s
       * @param flag
       */
      setShowObedRuCodeText(s, flag: boolean) {
        s.isShowedObedRuCodeText = flag
      }
    },
    getters
  })

  vuexModule.register(store)

  /**
   * Добавляем товар в состав корзины
   * @param state
   * @param productId
   * @param count
   */
  const addItem = ({
    productId,
    count
  }: {
    productId: ItemsInputType['productId'];
    count: NonNullable<ItemsInputType['count']>;
  }) => {
    log('Добавляем товар в состав корзины', productId, count)
    const items = cloneDeep(vuexModule.state.items)
    const findItem = items.find(item => item.productId === productId)
    if (findItem) {
      findItem.count = (findItem.count ?? 0) + count
    } else {
      items.push({ productId, count })
    }
    vuexModule.mutations.setItems(items)
  }

  /**
   * Удаляем товар из состава корзины
   * @param state
   * @param productId
   * @param count
   */
  const deleteItem = ({
    productId,
    count
  }: {
    productId: ItemsInputType['productId'];
    count: NonNullable<ItemsInputType['count']>;
  }) => {
    log('Удаляем товар из состава корзины', { productId, count })
    const items = vuexModule.state.items
    const findItem = items.find(item => item.productId === productId)
    if (findItem) {
      const resultCount = (findItem.count ?? 0) - count
      findItem.count = resultCount >= 0 ? resultCount : 0
    } else {
      items.push({ productId, count: 0 })
    }
    vuexModule.mutations.setItems(items)
  }

  /**
   * Изменить кол-во товаров в корзине
   * @param state
   * @param productId
   * @param quality
   */
  const changeQuality = ({
    productId,
    quality
  }: {
    productId: Scalars['ID'];
    quality: number;
  }) => {
    log('Меняем кол-во товаров в корзине', { productId, quality })
    const quantityNow = vuexModule.state.items.find(
      item => item.productId === productId
    )?.count
    const diff = quality - (quantityNow ?? 0)
    if (diff > 0) {
      addItem({ productId, count: diff })
    } else if (diff < 0) {
      deleteItem({ productId, count: -diff })
    }
  }

  /**
   * При изменении состава корзины делаем перерасчёт
   */
  const handlerItems = async(items: State['items'], oldItems: State['items']) => {
    log('Обрабатываем изменение состава корзины', { items, oldItems })
    if (items.length) {
      vuexModule.mutations.pushItemsHistory(
        items.map(({ productId }) => productId)
      )
    }
  }

  /**
   * Сценарий оплаты корзины
   */
  const payCart = async(provider: Provider): Promise<Scalars['ID']> => {
    try {
      log('Начинаем оплату корзины', { provider })
      terminal.mutations.setPaymentErrors(null)
      if (!vuexModule.state.items) {
        throw new CartError('Отсутствуют продукты в корзине для оплаты.',
          {
            items: vuexModule.state.items
          }
        )
      }
      const products = cloneDeep(vuexModule.state.items)
      const stateUuid = v4()
      const productsDetailed = cloneDeep(vuexModule.getters.products)
      const productsForSetWaitPayment = productsDetailed.map(item => ({
        count: item.quality,
        productId: item.id,
        priceWithDiscount: item.priceWithDiscount,
        priceWithoutDiscount: item.priceWithoutDiscounts
      }))
      const items: ItemUnit[] = productsDetailed.map(product => ({
        title: product.title,
        productId: product.id,
        price: String(product.priceWithDiscount),
        quantity: String(product.quality)
      }))
      const codes = cloneDeep(vuexModule.state.promoCodes)
      const serverCart = await getNewServerCart()
      const cartId = serverCart.id
      const price = vuexModule.getters.totalPrice
      if (price === null) {
        throw new CartError('Отсутствует стоимость для совершения оплаты', {
          items: products,
          id: cartId,
          promoCodes: codes,
          price
        })
      } else if (serverCart.status !== CartStatus.New) {
        throw new CartError(`Корзина для оплаты не в статусе "${CartStatus.New}"`, {
          items: products,
          id: cartId,
          promoCodes: codes,
          price
        })
      } else {
        if (customer.customerId) {
          const customerId = customer.customerId
          const spendBonuses = customer.spendScores
          await apolloClient
            .mutate<ApplyLoyaltyCartMutation, ApplyLoyaltyCartMutationVariables>({
              mutation: applyLoyaltyCart,
              variables: {
                cartId,
                customerId,
                spendBonuses,
                stateUuid
              }
            })
        }
        await setWaitPayment(cartId, productsForSetWaitPayment, codes)
        const transactionNumber = price > 0 && !customer.spendScores ? await (
          await terminalServiceModule()
        ).makePayment({
          cartId,
          price,
          provider,
          items: provider === Provider.Yandexgo || provider === Provider.Yandexbadge || provider === Provider.Obedru ? items : null
        }) : null
        const paymentProvider = (() => {
          switch (provider) {
            case Provider.Foodcard: return PaymentTypes.Foodcard
            case Provider.Nalunch: return PaymentTypes.Nalunch
            case Provider.Yandexgo: return PaymentTypes.Yandexgo
            case Provider.Yandexbadge: return PaymentTypes.Yandexbadge
            case Provider.Obedru: return PaymentTypes.Obedru
            case Provider.Psbank: return PaymentTypes.Psbank
            default: return PaymentTypes.Sberbank
          }
        })()
        await apolloClient.mutate<PayCartMutation, PayCartMutationVariables>({
          mutation: payCartGraphql,
          variables: {
            cartId,
            transactionNumber,
            paymentProvider
          }
        })
        return cartId
      }
    } catch (error) {
      log('Получаем ошибку оплаты payCart', error)
      if (error instanceof ApolloError) {
        const errorPayment = error.graphQLErrors?.map(err => ({ message: err?.extensions?.user_message }))
        terminal.mutations.setPaymentErrors(errorPayment)

        error.graphQLErrors.forEach(e => {
          if (e.extensions && 'user_message' in e.extensions) {
            const message = e.extensions.user_message || e.message
            const type = e.extensions.code
            if (Object.values(ErrorTypes).includes(type)) {
              throw new ServerErrors(message, type)
            }
            throw new ServerErrors(message)
          }
        })
      }
      throw error
    }
  }

  /**
   * Использование бонусных баллов для оплаты корзины
   */
  const payWithScores = (): void => {
    const scores = customer.scores
    const price = vuexModule.getters.totalPrice
    if (price && scores && price <= scores) {
      customer.useScores(
        scores - price
      )
    }
  }

  /**
   * Получение QR кода от локального сервиса для оплаты через Наланч
   */
  const getQrCodeForNalunch = async(): Promise<string> => {
    const serverCart = await getNewServerCart()
    const cartId = serverCart.id
    const price = vuexModule.getters.totalPrice
    if (price === null) {
      throw new CartError(
        'Отсутствует стоимость для генерации Qr кода',
        serverCart
      )
    } else {
      try {
        return await (
          await terminalServiceModule()
        ).generateNaLunchQrCode({
          cartId,
          price
        })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        log('Ошибка генерации Qr кода', {
          error,
          cartId,
          price
        })
        Sentry.captureMessage(
          'Ошибка генерации Qr кода',
          {
            extra: {
              error,
              cartId,
              price
            }
          }
        )
        throw error
      }
    }
  }

  /**
   * Списать товары из корзины (сервисный режим)
   * @param action
   */
  const inventoryCart = async(
    action: InventoryActionTypes,
    salesPointId: string
  ): Promise<void> => {
    const serverCart = await getNewServerCart()
    const cartId = serverCart.id
    const operatorId = operator.operatorId
    const codes = cloneDeep(vuexModule.state.promoCodes)
    const productsDetailed = cloneDeep(vuexModule.getters.products)
    const productsForSetWaitPayment = productsDetailed.map(item => ({
      count: item.quality,
      productId: item.id,
      priceWithDiscount: item.priceWithDiscount,
      priceWithoutDiscount: item.priceWithoutDiscounts
    }))
    try {
      await setWaitPayment(cartId, productsForSetWaitPayment, codes)
      await apolloClient.mutate<InventoryCartMutation, InventoryCartMutationVariables>({
        mutation: inventoryCartGraphql,
        variables: {
          action,
          cartId,
          operatorId,
          salesPointId
        }
      })
      await deactualizeCart(cartId)
      vuexModule.mutations.resetState()
    } catch (error) {
      log('Ошибка списания товаров', {
        error,
        action,
        cartId,
        operatorId
      })
      Sentry.captureMessage(
        'Ошибка списания товаров',
        {
          extra: {
            error,
            action,
            cartId,
            operatorId
          }
        }
      )
      throw error
    }
  }

  const cartInterface: CartInterface = {
    get items() {
      return vuexModule.state.items
    },
    get products() {
      return vuexModule.getters.products
    },
    get isShowedYandexCodeText() {
      return vuexModule.state.isShowedYandexCodeText
    },
    get isShowedObedRuCodeText() {
      return vuexModule.state.isShowedObedRuCodeText
    },
    get productsHistory() {
      return vuexModule.getters.productsHistory
    },
    get frontPrice() {
      return vuexModule.getters.frontPrice
    },
    get commonPrice() {
      return vuexModule.getters.commonPrice
    },
    get totalPrice() {
      return vuexModule.getters.totalPrice
    },
    get deletedItems() {
      return vuexModule.state.deletedItems
    },
    addItem,
    deleteItem,
    reset: (id?: Scalars['ID']) => {
      if (id) {
        deactualizeCart(id)
      }
      vuexModule.mutations.resetState()
      customer.reset()
    },
    pushItemsHistory: vuexModule.mutations.pushItemsHistory,
    changeQuality,
    restartPayment: payCart,
    payCart,
    payWithScores,
    inventoryCart,
    getReceipt,
    getQrCodeForNalunch,
    increaseDeletedItems: vuexModule.mutations.increaseDeletedItems,
    resetDeletedItems: vuexModule.mutations.resetDeletedItems,
    setShowYandexCodeText: vuexModule.mutations.setShowYandexCodeText,
    setShowObedRuCodeText: vuexModule.mutations.setShowObedRuCodeText
  }

  return cartInterface
}

export const cart: CartInterface = setupCartModule()
