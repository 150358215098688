/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode } from 'graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { SubscribeToMoreOptions, UpdateQueryFn } from 'apollo-client/core/watchQueryOptions'
import { cloneDeep } from 'lodash-es'

export type TypeSubscribe = 'added' | 'updated' | 'deleted'

/**
 * Для использования сортировки по полям, указывать orderBy и поле по которому сортируем.
 * Например: "-id" где "id" соответствует ASC (по возрастанию),
 * а "-id" соответствует DESC (по убыванию).
 * Также можно указывать несколько полей для сортировки: ["-createdAt", "-id"]
 * Имя поля модели, по которому выполняется фильтрация.
 * Имена полей могут пересекать отношения, объединяя связанные части с помощью разделителя поиска ORM (__).
 * например, продукт "manufacturer__name".
 */
export type OrderBy = Maybe<Array<Maybe<string>> | Maybe<string>>

// более подробная типизация пока не используется
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SortVariables = {orderBy?:OrderBy, [K:string]:any}
export type UnMaybe<T> = Exclude<T, null | undefined>;

type FieldName<QR> = Exclude<keyof QR, '__typename'>

type GeneralQueryResult<QR> = (
  { __typename: 'Query' }
  & Record<FieldName<QR>, Maybe<({
    __typename: any
    edges: Array<Maybe<({
      __typename: any
      node: Maybe<({
        __typename: any,
        id: number | string
      }
        // более подробная типизация пока не используется
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        & Record<any, any>
      )>
    })> >,
  })> >
)

type GeneralSubscriptionResult<SR> = (
  { __typename: 'WESubscriptions' }
  & Record<FieldName<SR>, Maybe<(
    { __typename: string }
  // более подробная типизация пока не используется
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    & Record<any, any>
    )> >
  );

/**
 * Объект настройки и обработки graphql подписок
 */
export class SubscribeOptions<QueryResult extends GeneralQueryResult<QueryResult>,
  TSubscriptionData extends GeneralSubscriptionResult<TSubscriptionData>,
  TSubscriptionVariables extends SortVariables, TQueryVariables extends SortVariables> {
  readonly document: DocumentNode
  readonly variables?: TSubscriptionVariables
  readonly onError?: SubscribeToMoreOptions<QueryResult, TSubscriptionVariables, TSubscriptionData>['onError']

  private queryDocument: DocumentNode
  private queryVariables?: TQueryVariables
  private readonly type: TypeSubscribe

  constructor(options:{
    type: TypeSubscribe,
    subscribeDocument: DocumentNode,
    queryDocument: DocumentNode
    queryVariables?: TQueryVariables,
    variables?: TSubscriptionVariables,
    onError?: SubscribeToMoreOptions<QueryResult, TSubscriptionVariables, TSubscriptionData>['onError']
}) {
    this.document = options.subscribeDocument
    this.type = options.type
    this.queryDocument = options.queryDocument
    if (options.queryVariables) {
      this.queryVariables = options.queryVariables
    }
    if (options.variables) {
      this.variables = options.variables
    }
    if (options.onError) {
      this.onError = options.onError
    }
  }

  private get subscribeFieldNode() {
    const operationDefinition = this.document.definitions.find((definitionNode) => {
      return 'operation' in definitionNode && definitionNode.operation === 'subscription'
    })
    if (!operationDefinition || operationDefinition.kind !== 'OperationDefinition') {
      throw new Error('subscription operation not found')
    }
    const field = operationDefinition.selectionSet.selections.find(({ kind }) => kind === 'Field')
    if (!field || !('name' in field)) {
      throw new Error('no subscription field found')
    }
    return field
  }

  private get subscribeNameField() {
    if (!('selectionSet' in this.subscribeFieldNode)) {
      throw new Error('selectionSet not found')
    }
    const field = this.subscribeFieldNode.selectionSet?.selections.find(({ kind }) => kind === 'Field')
    if (!field || !('name' in field)) {
      throw new Error('field in subscription not found')
    }
    return field.name.value
  }

  private get queryFieldNode() {
    const operationDefinition = this.queryDocument.definitions.find((definitionNode) => {
      return 'operation' in definitionNode && definitionNode.operation === 'query'
    })
    if (!operationDefinition || operationDefinition.kind !== 'OperationDefinition') {
      throw new Error('query operation not found')
    }
    const field = operationDefinition.selectionSet.selections.find(({ kind }) => kind === 'Field')
    if (!field || !('name' in field)) {
      throw new Error('no subscription field found')
    }
    return field
  }

  private get queryNameField(): Extract<FieldName<QueryResult>, string> {
    return this.queryFieldNode.name.value as Extract<FieldName<QueryResult>, string>
  }

  /**
   * Название поля подписки
   */
  private get subscribeName(): FieldName<TSubscriptionData> {
    const subscribeName = this.subscribeFieldNode.name.value
    if (!subscribeName) {
      throw new Error('subscription field name not found')
    }
    return subscribeName as FieldName<TSubscriptionData>
  }

  private addedHandler(previous: QueryResult, { subscriptionData: { data }, variables }: {
    subscriptionData: {
      data: TSubscriptionData;
    };
    variables?: TSubscriptionVariables;
  }): QueryResult {
    const previousQueryResult = cloneDeep(previous)
    if (process.env.NODE_ENV !== 'production') {
      console.log('--updateQuery added', this.subscribeName)
    }
    const subscribeName = this.subscribeName
    if (!data[subscribeName]) {
      throw new Error('Invalid "subscribeName"')
    }
    if (!(this.subscribeNameField in data[subscribeName])) {
      throw new Error(`"subscriptionData" cannot property ${this.subscribeNameField}`)
    }
    const newNode = data[subscribeName]?.[this.subscribeNameField]
    if (!(this.queryNameField in previousQueryResult)) {
      throw new Error(`"previousQueryResult" cannot property ${this.queryNameField}`)
    }
    const edges = previousQueryResult[this.queryNameField]?.edges
    if (!edges) {
      throw new Error('Invalid "edges"')
    }
    if (newNode) {
      const __typename = edges[0]?.__typename || ''
      const edge = {
        __typename,
        node: newNode
      }
      edges.push(edge)
    }
    if (variables?.orderBy) {
      this.sort(previousQueryResult, variables.orderBy)
    } else if (this.queryVariables?.orderBy) {
      this.sort(previousQueryResult, this.queryVariables.orderBy)
    }
    return previousQueryResult
  }

  private updatedHandler(previous: QueryResult, { subscriptionData: { data }, variables }: {
    subscriptionData: {
      data: TSubscriptionData;
    };
    variables?: TSubscriptionVariables;
  }): QueryResult {
    const previousQueryResult = cloneDeep(previous)
    if (process.env.NODE_ENV !== 'production') {
      console.log('--updateQuery update', this.subscribeName)
    }
    const newNode = data[this.subscribeName]?.[this.subscribeNameField]

    if (newNode) {
      const edges = previousQueryResult[this.queryNameField]?.edges
      if (!edges) {
        throw new Error('Invalid "edges"')
      }

      if (newNode.id) {
        const oldEdge = edges.find((edge) => {
          return edge?.node?.id === newNode.id
        })
        if (oldEdge) {
          oldEdge.node = newNode
        }
      } else if (this.queryNameField === 'priceModification') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const priceModification: QueryResult = {
          [this.queryNameField]: {
            __typename: 'PriceModificationTypeConnection',
            edges: newNode.map((item: any) => ({ node: { __typename: 'PriceModificationTypeEdge', ...item } }))
          }
        }

        return priceModification
      }

      if (variables?.orderBy) {
        this.sort(previousQueryResult, variables.orderBy)
      } else if (this.queryVariables?.orderBy) {
        this.sort(previousQueryResult, this.queryVariables.orderBy)
      }
    }

    return previousQueryResult
  }

  private deletedHandler(previous: QueryResult, { subscriptionData: { data } }: {
    subscriptionData: {
      data: TSubscriptionData;
    };
    variables?: TSubscriptionVariables;
  }): QueryResult {
    const previousQueryResult = cloneDeep(previous)
    if (process.env.NODE_ENV !== 'production') {
      console.log('--updateQuery delete', this.subscribeName)
    }
    const newNode = data[this.subscribeName]?.[this.subscribeNameField]

    if (newNode) {
      const edges = previousQueryResult[this.queryNameField]?.edges
      if (!edges) {
        throw new Error('Invalid "edges"')
      }
      const indexDeleteEdge = edges.findIndex((edge) => {
        return edge?.node?.id === newNode.id
      })
      if (indexDeleteEdge > -1) {
        edges.splice(indexDeleteEdge, 1)
      }
    }
    return previousQueryResult
  }

  private sort(previousQueryResult: QueryResult, orderBy: OrderBy): QueryResult {
    const edges = previousQueryResult[this.queryNameField]?.edges
    if (!edges) {
      throw new Error('Invalid "edges"')
    }
    if (typeof orderBy === 'string') {
      const isRevers = orderBy.indexOf('-') === 0
      const path = orderBy.replace('-', '').split('__')
      edges.sort((firstEl, secondEl) => {
        if (firstEl?.node && secondEl?.node) {
          const a = path.reduce((acc, val) => { return acc[val] }, firstEl.node)
          const b = path.reduce((acc, val) => { return acc[val] }, secondEl.node)
          const isNumbers = !([a, b].find(v => (['string', 'boolean'].includes(typeof v))))
          if (isNumbers) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return isRevers ? b - a : a - b
          }
          const isString = !([a, b].find(v => (typeof v !== 'string')))
          if (isString) {
            const stringSort = () => {
              if (a > b) {
                return 1
              }
              if (a < b) {
                return -1
              }
              return 0
            }
            return isRevers ? stringSort() * -1 : stringSort()
          }
        }
        return 0
      })
    } else if (Array.isArray(orderBy)) {
      orderBy.forEach(itemOrderBy => {
        this.sort(previousQueryResult, itemOrderBy)
      })
    }
    return previousQueryResult
  }

  get updateQuery(): UpdateQueryFn<QueryResult, TSubscriptionVariables, TSubscriptionData> | undefined {
    switch (this.type) {
      case 'added':
        return this.addedHandler.bind(this)
      case 'updated':
        return this.updatedHandler.bind(this)
      case 'deleted':
        return this.deletedHandler.bind(this)
    }
  }
}
